import axios from "axios";
import { FeamEntities } from "../models/feam-entities";

class AviationStackflightDataService {
  #baseUrl: string = process.env.REACT_APP_FLIGHT_URI!;
  #flightsPath: string = process.env.REACT_APP_FLIGHT_PATH!;
  #airlinesPath: string = process.env.REACT_APP_FLIGHT_AIRLINES_PATH!;
  #access_key: string = process.env.REACT_APP_FLIGHT_ACCESS_KEY!;

  async getByArrivalStation(
    station: string,
    airline: string,
    date: string,
    registration: string
  ): Promise<FeamEntities.IFlightDataResult> {
    const airlineName = this.#getCustomerName(airline);
    const fullUrl = this.#baseUrl + '/' + this.#flightsPath + '?access_key=' + this.#access_key + '&arr_iata=' + station + '&airline_name=' + airlineName + '&flight_date=' + date

    try {
      const apiClient = axios.create({
        baseURL: this.#baseUrl,
      });
      // const response = await apiClient.get<FeamEntities.IAviationStackResponse>(`${this.#flightsPath}`, {
      //   params: {
      //     access_key: this.#access_key,
      //     arr_iata: station,
      //     airline_name: airline,
      //     flight_date: date
      //   },
      // });
      // if (response.data.data.length === 0) {

      const splitResponse = await apiClient.get<FeamEntities.IAviationStackResponse>(`${this.#flightsPath}`, {
        params: {
          access_key: this.#access_key,
          arr_iata: station,
          airline_name: airlineName,
          flight_date: date
        },
      });
      return { flightData: splitResponse.data.data, url: fullUrl };
      // if (!registration) {
      //   return splitResponse.data.data;
      // } else {
      //   return splitResponse.data.data.filter(
      //     f => f.aircraft != null && f.aircraft.registration === registration
      //   );
      // }
      // }
      // if (!registration) {
      //   return response.data.data;
      // } else {
      //   return response.data.data.filter(
      //     f => f.aircraft != null && f.aircraft.registration === registration
      //   );
      // }
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return { flightData: [], url: fullUrl };
  }
  async getByDepartureStation(
    station: string,
    airline: string,
    date: string,
    registration: string
  ): Promise<FeamEntities.IFlightDataResult> {

    const airlineName = this.#getCustomerName(airline);
    const fullUrl = this.#baseUrl + '/' + this.#flightsPath + '?access_key=' + this.#access_key + '&dep_iata=' + station + '&airline_name=' + airlineName + '&flight_date=' + date
    try {
      const apiClient = axios.create({
        baseURL: this.#baseUrl,
      });

      // const response = await apiClient.get<FeamEntities.IAviationStackResponse>(`${this.#flightsPath}`, {
      //   params: {
      //     access_key: this.#access_key,
      //     dep_iata: station,
      //     airline_name: airline,
      //     flight_date: date
      //   },
      // });
      // if (response.data.data.length === 0) {
      const airlineName = this.#getCustomerName(airline);
      const splitResponse = await apiClient.get<FeamEntities.IAviationStackResponse>(`${this.#flightsPath}`, {
        params: {
          access_key: this.#access_key,
          dep_iata: station,
          airline_name: airlineName,
          flight_date: date
        },
      });
      return { flightData: splitResponse.data.data, url: fullUrl };
      //   if (!registration) {
      //     return splitResponse.data.data;
      //   } else {
      //     return splitResponse.data.data.filter(
      //       f => f.aircraft != null && f.aircraft.registration === registration
      //     );
      //   }
      // }
      // if (!registration) {
      //   return response.data.data;
      // } else {
      //   return response.data.data.filter(
      //     f => f.aircraft != null && f.aircraft.registration === registration
      //   );
      // }
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return { flightData: [], url: fullUrl };
  }

  async getAirlinesByName(
    airline: string
  ): Promise<any> {
    try {
      const apiClient = axios.create({
        baseURL: this.#baseUrl,
      });
      const response = await apiClient.get<any>(`${this.#airlinesPath}`, {
        params: {
          access_key: this.#access_key,
          search: airline,
        },
      });
      if (response.data.data.length === 0) {
        const airlineName = this.#getCustomerName(airline);
        const splitResponse = await apiClient.get<any>(`${this.#airlinesPath}`, {
          params: {
            access_key: this.#access_key,
            airline_name: airlineName,
          },
        });
        return splitResponse.data.data.find((x: { airline_name: string; }) => x.airline_name === airline);
      }
      return response.data.data.find((x: { airline_name: string; }) => x.airline_name === airline);
    } catch (err: any) {
      console.log(err.response?.data || err);
      return null;
    }
  }

  #getCustomerName(airline: string) {
    const splitAirline = airline.split(" ");
    const airlineName = splitAirline.length > 2
      ? splitAirline[0] + " " + splitAirline[1]
      : splitAirline[0];
    return airlineName;
  }
}

const aviationStackflightDataService = new AviationStackflightDataService();
export default aviationStackflightDataService;
