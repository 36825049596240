import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { CwsAdvancedSearchSection, CwsGeneralHeader, D407FormViewSelection, NoRecordFound } from "../common";
import { usePrint } from "../../../PageLayout";
import { Fragment, useEffect, useState } from "react";
import { FeamEntities } from "../../../models/feam-entities";
import { searchType } from "../../../models/constants";
import timeAndLaborService from "../../../services/time-labor-service";
import TimeAndLabourDetail from "./TimeAndLabourDetail";
import { isIOS, isMobile } from "react-device-detect";

export default function TimeAndLaborContainer() {
    const [searching, setSearching] = useState<boolean>(false);
    const [id, setId] = useState<string | undefined>(undefined);
    const [data, setData] = useState<FeamEntities.ITimeAndLabor[]>([]);
    const { isPrint, setIsPrint } = usePrint();
    useEffect(() => {
        if (isPrint) {
            setTimeout(() => {
                printHandler();
            }, 300);
        }
    }, [isPrint])
    return (

        <LoadingOverlayWrapper className="feam-postion-fixed" active={false} text={"Loading Content"} spinner>
            <>
                <div className={`${!isPrint ? "container cws-advance-search feam-m-5" : ""}`} >
                    <div style={{ display: isPrint ? "none" : "block" }}>
                        <CwsGeneralHeader title="Time & Labor" />
                        <CwsAdvancedSearchSection onSearch={searchHandler} searchType={searchType.timeAndLabor} onClear={() => { setData([]) }} maintenanceTypesForDropdown={[]} searching={searching} />
                    </div>
                    {data.length === 0 && <NoRecordFound message={"No Data found"} />}

                    {data.length > 0 && <table className="table m-0 p-0 w-100 customer-worksheet-time-labor mb-3">
                        <thead className="header-col">
                            <tr className="text-uppercase header-col">
                                <th scope="col" className="header-col">Name</th>
                                <th scope="col" className="header-col text-nowrap">Employee Code</th>
                                <th scope="col" className="header-col text-nowrap">Employee Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 && data.map((d, i) => {
                                return <TimeAndLabourDetail key={`${d.mechanicName}_${i}`} timeAndLabour={d} selectedId={(val: string) => { setId(val) }} />
                            })}
                        </tbody>
                    </table>}
                    <D407FormViewSelection id={id} resetData={resetDataHandler} />
                </div>
            </>
        </LoadingOverlayWrapper >
    )

    async function searchHandler(filter: FeamEntities.IWorkSheetFilter) {
        if (!filter.stationId || !filter.startDate || !filter.endDate) {
            alert('Please select station, start date and end date.')
            return;
        }
        setSearching(true)
        const result = await timeAndLaborService.getByFilter(filter);
        setData(result ?? [])
        setSearching(false);

    }
    function printHandler() {
        window.addEventListener(isMobile && !isIOS ? "focus" : "afterprint", listener);
        window.print();
        return () => {
            window.removeEventListener(isMobile && !isIOS ? "focus" : "afterprint", listener);
        }
    }

    function listener(e: any) {
        setIsPrint(false);
    }
    function resetDataHandler() {
        setId(undefined);

    }
}