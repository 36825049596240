import { useState } from "react";
import { IProps } from "../../../models/feam-entities";
import { formatStringDate } from "../../../models/feam-utils";
import { D407FormViewSelection } from "../common";
import * as utils from "../../../models/feam-utils";
import { useFeamAuth } from "../../hooks";
import * as BootStrapIcon from "react-bootstrap-icons";

export default function BillingReviewWorkDetail(
  props: IProps.IBillingReviewWorkDetailProps
) {
  const [id, setId] = useState(undefined);
  const [dynamicView, setDynamicView] = useState(false);
  const { user } = useFeamAuth();
  const [showHoursDetail, setHoursDetail] = useState(false);
  return (
    <>
      <div
        id="searchSection"
        className="d-flex justify-content-between align-items-center header-col w-100 p-2 pt-1 pb-1 fw-bold rounded text-uppercase "
      >
        <button
          type="button"
          className="btn btn-light-secondary btn-block w-100 text-uppercase m-0  p-2 feam-font-size-sm text-start align-items-center d-flex gap-2 fw-bold"
          onClick={() => setHoursDetail((p) => !p)}
          aria-controls="Hours Detail"
          aria-expanded={showHoursDetail}
        >
          {!showHoursDetail ? (
            <>
              {" "}
              <BootStrapIcon.ArrowsFullscreen size={16} /> Show Hours Detail
            </>
          ) : (
            <>
              <BootStrapIcon.FullscreenExit size={16} /> Hide Hours Detail
            </>
          )}
        </button>
      </div>
      {showHoursDetail && (
        <div className="p-2">
          <table className="table">
            <thead>
              <tr>
                <th>D407Id</th>
                <th className="text-nowrap">Week No.</th>
                <th className="text-nowrap">Entry Date</th>
                <th className="text-nowrap">Hours</th>
                <th className="text-nowrap">Pay Code</th>
                <th className="text-nowrap">Position Code</th>
                <th className="text-nowrap">Position Name</th>
                <th className="text-nowrap">MaintenanceType</th>
                <th className="text-nowrap">ApprovedBy</th>
                {(utils.isSuperAdmin(user) ||
                  utils.isBillingApprover(user)) && (
                    <th className="text-nowrap text-end">Extended Price</th>
                  )}
              </tr>
            </thead>
            <tbody>
              {props.billingReviewData.map((h, hi) => {
                return (
                  <tr key={`d407perManHoursBilling_${hi}`}>
                    <td>
                      <a
                        className="link-offset-2 link-underline link-underline-opacity-100"
                        href="javascript: void(0);"
                        onClick={(e) => {
                          e.preventDefault();
                          //@ts-ignore
                          setId(h.d407Id?.toString());
                        }}
                      >
                        {h.d407Id}
                      </a>
                    </td>
                   
                    <td>{h.weekNum}</td>
                    <td className="text-nowrap">
                      {h.entryDate
                        ? formatStringDate(h.entryDate.toString().split("T")[0])
                        : ""}
                    </td>
                    <td className="text-nowrap">{h.currentHours}</td>
                    <td className="text-nowrap">{h.payCode}</td>
                    <td>{h.workTitleCode}</td>
                    <td>{h.workTitleName}</td>
                    <td>{h.d407MaintenanceType}</td>
                    <td>{h.approvedBy}</td>
                    {(utils.isSuperAdmin(user) ||
                      utils.isBillingApprover(user)) && (
                        <td className="text-end">
                          ${h.rateUSD ? (+h.rateUSD).toFixed(2) : 0}
                        </td>
                      )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <D407FormViewSelection id={id} resetData={(e) => setId(undefined)} />
    </>
  );
}
