import { useState } from "react";
import { IProps } from "../../../models/feam-entities";
import * as BootStrapIcon from "react-bootstrap-icons";
import BillingReviewStation from "./BillingReviewStation";
import TailMaterialView from "./TailMaterialView";

export default function HourTailSummaryContainer(
  props: IProps.IBillingReviewStationProps
) {
  const [showSummaryAndTailDetail, setShowSummaryAndTailDetail] = useState(true);
  return (
    <>
    <button
    type="button"
    className="btn btn-light-secondary btn-block w-100 text-uppercase m-0  p-2  feam-font-size-sm text-start align-items-center d-flex gap-2 fw-bold"
    onClick={() => setShowSummaryAndTailDetail((p) => !p)}
    aria-controls="Full Detail"
    aria-expanded={showSummaryAndTailDetail}
  >
    {!showSummaryAndTailDetail ? (
      <>
        <BootStrapIcon.ArrowsFullscreen size={16} /> Show Hours
        Summary & Tail Material Summary
      </>
    ) : (
      <>
        <BootStrapIcon.FullscreenExit size={16} /> Hide Hours Summary
        & Tail Material Summary
      </>
    )}
  </button>
  {showSummaryAndTailDetail && (
    <>
      <BillingReviewStation d407BillingReview={props.d407BillingReview} billingReviewData={[...(props.billingReviewData ?? [])]} tailMaterialsData={[...(props.tailMaterialsData ?? [])]} tailMaterialDetailsData={[...(props.tailMaterialDetailsData ?? [])]}/>
      <TailMaterialView data={[...props.tailMaterialDetailsData]} />
    </>
  )}
  </>
  );
}
