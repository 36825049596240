import { Formik, FormikProps } from "formik";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import * as BootStrapIcon from "react-bootstrap-icons";
import { FeamFormArrayFieldSelect, FeamFormInput, FeamFormSelect } from "../../common";
import UserManagementStations from "./UserManagementStations";

export default function UserManagementForm(props: IProps.IUserManagementFormProps) {
    return (
        <div>
            <Formik
                initialValues={props.d407UserRole}
                enableReinitialize={true}
                onSubmit={async (
                    values: FeamEntities.ID407userRole
                ) => {
                    await saveHandler(values);
                }}
            >
                {(fprops: FormikProps<FeamEntities.ID407userRole>) => {
                    return (
                        <div className="mt-3 pb-3 feam-advance-search w-100">
                            <div id="searchSection" className="d-flex justify-content-between align-items-center header-col w-100 p-2 fw-bold rounded text-uppercase mb-3">
                                <div className="d-flex gap-3 align-items-center justify-content-start">
                                    <button type="button" title="Back" className={`btn btn-secondary d-flex flex-inline align-items-center`} onClick={() => { props.onBack() }} ><BootStrapIcon.ArrowLeft size={16} className="" /></button>
                                    <span>{props.d407UserRole.d407userRoleId && props.d407UserRole.d407userRoleId > 0 ? "Edit" : "Add"} User Role</span>
                                </div>
                                <div className="d-flex gap-1">
                                    <button type="submit" title="Save" className={`btn btn-secondary d-flex flex-inline align-items-center`} disabled={isDisableSave(fprops)} onClick={() => fprops.submitForm()}>
                                        {!props.saving ? (
                                            <BootStrapIcon.Save size={16} />
                                        ) : (
                                            <>
                                                <span
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                Saving...
                                            </>
                                        )}</button>
                                    <button type="reset" title="Reset" className={`btn btn-outline-secondary d-flex flex-inline align-items-center`} disabled={!fprops.dirty} onClick={() => { fprops.resetForm() }} ><BootStrapIcon.XCircle size={16} /></button>
                                </div>
                            </div>
                            <div className="form-row ps-3 pe-2">
                                <div className=" col-auto mb-3">
                                    <label htmlFor="employeeCode">Emp Code-Name</label>
                                    <FeamFormSelect feamSelector="employeeCode" defaultText="Select Emp Code-Name" data={props.dropdownUsers} onChange={(e) => nameChangedHandler(e, fprops)} formProps={fprops} hideLoader={false} disabled={!!(fprops.values.d407userRoleId && fprops.values.d407userRoleId > 0)} />
                                </div>
                                <div className=" col-auto mb-3">
                                    <label htmlFor="userEmail">Email</label>
                                    <FeamFormInput feamSelector={"userEmail"} formProps={fprops} disabled={true} />
                                </div>
                                <div className="col-auto mb-3">
                                    <label htmlFor="d407userRoleTypeMappers.0.d407userRoleTypeId">Role Type</label>
                                    {/** @ts-ignore */}
                                    <FeamFormArrayFieldSelect data={props.dropdownUserRoleTypes} feamArraySelector="d407userRoleTypeMappers" feamItemIndex={0} feamSelector="d407userRoleTypeId" defaultText="Select Role Type" onChange={(e) => { uerRoleTypeChangedHandler("d407userRoleTypeMappers.0.d407userRoleTypeId", e, fprops) }} formProps={fprops} hideLoader={false} />
                                </div>
                                <div className=" col-auto mb-3">
                                    <label htmlFor="Stations">Stations</label>
                                    <UserManagementStations formProps={fprops} multiStations={props.multiStations} disable={fprops.values.d407userRoleTypeMappers?.length === 1 && (fprops.values.d407userRoleTypeMappers[0].d407userRoleTypeId === 1 || fprops.values.d407userRoleTypeMappers[0].d407userRoleTypeId === 5)} />
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik >
        </div >
    )

    function isDisableSave(fprops: FormikProps<FeamEntities.ID407userRole>) {

        return !(fprops.dirty && fprops.values.employeeCode && fprops.values.d407userRoleTypeMappers?.length === 1
            && fprops.values.d407userRoleTypeMappers[0].d407userRoleTypeId
            && (
                (([1,5].includes(fprops.values.d407userRoleTypeMappers[0].d407userRoleTypeId)) && fprops.values.d407userRoleStationMappers?.length === 0)
                ||
                //@ts-ignore
                (![1,5].includes(fprops.values.d407userRoleTypeMappers[0].d407userRoleTypeId) && fprops.values.d407userRoleStationMappers?.length > 0)))
    }
    function nameChangedHandler(e: React.ChangeEvent<HTMLSelectElement>, fprops: FormikProps<FeamEntities.ID407userRole>) {
        const user = props.users.find(x => x.employeeCode?.trim() === e.target.value.trim());
        if (user) {
            fprops.setFieldValue("userEmail", user.workEmail?.trim());
        } else {
            fprops.setFieldValue("userEmail", "");
        }
        fprops.setFieldValue("employeeCode", e.target.value)

    }

    function uerRoleTypeChangedHandler(fieldSelector: string, e: React.ChangeEvent<HTMLSelectElement>, fprops: FormikProps<FeamEntities.ID407userRole>) {
        if (e.target.value === "1") {
            //super admin
            fprops.setFieldValue("d407userRoleStationMappers", [])
        }
        fprops.setFieldValue(fieldSelector, +e.target.value)
    }
    async function saveHandler(values: FeamEntities.ID407userRole) {

        await props.onSave(values);
    }

}