import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class FlightApiLogservice {
    #rootPath: string = "flightapilog";
    async create(flightApiLog: FeamEntities.IFlightApiLog): Promise<void> {
        try {
            await apiClient.post(this.#rootPath, flightApiLog);
        } catch (err: any) {
            console.log(err.response?.data || err);
        }
    }
}

const flightApiLogservice = new FlightApiLogservice();
export default flightApiLogservice;
