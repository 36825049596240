import {
  FeamEntities,
} from "../models/feam-entities";
import axios from "axios";
import oAuth2TokenForWebhookService from "./oauth2tokenforwebhook-service";

class WebhookService {
  #baseUrl: string = process.env.REACT_APP_WEBHOOK_URI!;
  #invokePath: string = "invoke";
  #apiVersion: string = "2016-06-01";

  async invokeD407Save(data: FeamEntities.ID407Data): Promise<any> {
    try {
      const apiPowerClient = axios.create({
        baseURL: this.#baseUrl,
      });
      const oAuthToken = await oAuth2TokenForWebhookService.getBearerToken();
      apiPowerClient.interceptors.request.use((request) => {
        //@ts-ignore
        request.headers["Authorization"] =
          "Bearer " + oAuthToken;
        return request;
      });
      const response = await apiPowerClient.post<any>(
        `${this.#invokePath}`,
        data,
        {
          params: {
            "api-version": this.#apiVersion,
          },
        }
      );
      return response.data.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
      return null;
    }
  }
}

const webhookService = new WebhookService();
export default webhookService;
