import { usePrint } from "../../../../PageLayout";
import { allWorkTitles, workTitles } from "../../../../models/constants";
import { FeamEntities, IProps } from "../../../../models/feam-entities";
import { CwsHeaderSection } from "../../common";
import GeneralInfoOfSummaryReport from "./GeneralInfoOfSummaryReport2";
import JobDescriptionOfSummaryReport from "./JobDescriptionOfSummaryReport2";
import MaterialsInfoOfSummaryReport from "./MaterialsInfoOfSummaryReport2";
import WorkAccomplishedOfSummaryReport from "./WorkAccomplishedOfSummaryReport2";
import MechanicAssignedWorkDetail from "./MechanicAssignedWorkDetail2";
import * as BootStrapIcon from 'react-bootstrap-icons';
import MechanicWorkDetail from "./MechanicWorkDetail2";
import TailSummary from "./TailSummary2";
import { Fragment } from "react";

export default function SummaryReport(props: IProps.ISummaryReportProps) {
    const { isPrint, setIsPrint } = usePrint();
    const { reportData } = props;
    return (
        <div className={!isPrint ? "d-flex d-inline-flex" : ""}>
            <div className="f-flex-column">
                {reportData.map((rdata: FeamEntities.ISummaryReport, rindex) => {
                    const mechanicsDetail = getByWorkTitle(rdata, workTitles.mechanics);
                    return (
                        <Fragment key={`reportsummany_${rindex}`} >
                            <div id={`reportsummany_${rindex}`} className="customer-worksheet-container customer-container-width customer-worksheet-container-min-height">
                                <div className="d-flex flex-column customer-worksheet-table-container">
                                    <CwsHeaderSection station={rdata.stationCode} />
                                    <div className="d-flex flex-column m-3 p-1">
                                        <GeneralInfoOfSummaryReport summaryReport={rdata} />
                                        <WorkAccomplishedOfSummaryReport summaryReport={rdata} />
                                        <div className="d-flex flex-row  d-inline-flex gap-3 w-100">
                                            <div className="border rounded p-1 pb-0" style={{ width: "50%" }}>
                                                <TailSummary rdata={rdata} />
                                            </div>
                                            <div className="border rounded p-1 pb-0" style={{ width: "50%" }}>
                                                <MaterialsInfoOfSummaryReport summaryReport={rdata} />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row  d-inline-flex gap-3 w-100">
                                            <div className="border rounded p-1 pb-0" style={{ width: "100%" }}>
                                                <JobDescriptionOfSummaryReport summaryReport={rdata} />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row d-inline-flex gap-3 w-100 ">
                                            <div className="d-flex flex-column " style={{ width: "100%" }}>
                                                <MechanicWorkDetail mechanicsDetail={mechanicsDetail} />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row d-inline-flex gap-3 w-100 ">
                                            <div className="d-flex flex-column  mt-3" style={{ width: "100%" }}>
                                                {
                                                    allWorkTitles.filter(x => x !== workTitles.mechanics).map((wt, index) => {
                                                        const dt = getByWorkTitle(rdata, wt);
                                                        return (
                                                            <Fragment key={`workTitle_${wt}_${index}`}>
                                                                {([workTitles.avionics, workTitles.leads, workTitles.other, workTitles.supervisors].includes(wt) || dt.length > 0) && <div className={`d-flex flex-column border w-100 rounded mb-3`}>
                                                                    <MechanicAssignedWorkDetail workTitle={wt} mechanicsAssignedDetail={dt} />
                                                                </div>}
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between d-inline-flex  w-100 mt-3">
                                            <div className="d-flex flex-row  d-inline-flex">
                                            </div>
                                            <div className="d-flex flex-row  d-inline-flex gap-1">
                                                {!isPrint && (rindex === reportData.length - 1) && <button type="button" title="Save & Print" className={`btn btn-secondary ms-1 d-flex flex-inline align-items-center`} onClick={() => { setIsPrint(true) }}><BootStrapIcon.Printer size={16} /></button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pagebreak"></div>
                        </Fragment>
                    )
                })}
            </div >
        </div>
    )

    function getByWorkTitle(rdata: FeamEntities.ISummaryReport, workTitle: string) {
        return rdata.mechanicsAssigned?.find(x => x.workTitle === workTitle)?.detail ?? []

    }
}