import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class CoreBillingService {
    #rootPath: string = "corebilling";
    async searchCoreBillingFlatRateData(filter: FeamEntities.IWorkSheetFilter): Promise<FeamEntities.ICoreBillingFlatRateData[]> {
        try {
            const response = await apiClient.get<FeamEntities.ICoreBillingFlatRateData[]>(`${this.#rootPath}/search/corebillingflatrate`, { params: filter });
            return response.data ?? [];
        } catch (err: any) {
            console.log(err.response?.data || err);
        }
        return [];
    }
    async searchCoreBillingPerHourData(filter: FeamEntities.IWorkSheetFilter): Promise<FeamEntities.ICoreBillingPerHourData[]> {
        try {
            const response = await apiClient.get<FeamEntities.ICoreBillingPerHourData[]>(`${this.#rootPath}/search/corebillingperhour`, { params: filter });
            return response.data ?? [];
        } catch (err: any) {
            console.log(err.response?.data || err);
        }
        return [];
    }
}

const coreBillingService = new CoreBillingService();
export default coreBillingService;
