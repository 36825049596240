import moment from "moment";
import { flightApiLogservice } from "../services";
import { FeamEntities } from "./feam-entities";
import { getTimeInAMAndPM } from "./feam-utils";

export async function flightApiLogHelper(arrivalResponse: FeamEntities.IAviationStackFlightData[], departureResponse: FeamEntities.IAviationStackFlightData[], customerId: number, stationId: number, tail: string, entryDate: string, arrivalUrl: string, departureUrl: string) {
    let arrivalData = "No flight data";
    let departureData = "No flight data";
    const time = getTimeInAMAndPM(new Date());
    if (entryDate?.length === 10) {
        entryDate = entryDate + " " + time;
    }
    entryDate = moment(entryDate).format('MM/DD/YYYY hh:mm A')
    if (arrivalResponse.length > 0) {
        arrivalData = JSON.stringify(arrivalResponse);
        if (departureResponse.length > 0) {
            departureData = JSON.stringify(departureResponse);
        }
    }
    const flightApiLog: FeamEntities.IFlightApiLog = {
        customerId: customerId,
        stationId: stationId,
        entryDate: entryDate,
        arrivalData: arrivalData,
        departureData: departureData,
        tail: tail,
        arrivalUrl: arrivalUrl,
        departureUrl: departureUrl
    };
    await flightApiLogservice.create(flightApiLog);
}