import moment from "moment";
import { userRoleType } from "./constants";
import { FeamEntities } from "./feam-entities";

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

/**
 * Get date in dd-mm-yyyy format
 * @param date Date
 * @returns
 */
export function formatDate(date?: Date) {
  if (!date) return "";
  let dt = new Date(date);
  return [
    padTo2Digits(dt.getDate()),
    padTo2Digits(dt.getMonth() + 1),
    dt.getFullYear(),
  ].join("-");
}
/**
 * Get date in dd-mm-yyyy hh:mm AM/PM format
 * @param date Date
 * @returns
 */
export function formatDateTime(date: Date, isWithoutTime: boolean = false) {
  if (!date) return undefined;
  let formattedDateOnly = [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("-");
  if (isWithoutTime) {
    return formattedDateOnly;
  } else {
    const time = getTimeInAMAndPM(date);
    return formattedDateOnly + " " + time;
  }
}

export function getTimeInAMAndPM(date: Date) {
  let hours = date.getHours();
  const minutes = padTo2Digits(date.getMinutes());
  let ampm = "AM";
  if (hours >= 12) {
    ampm = "PM";
    hours = hours - 12;
  }
  const hr = padTo2Digits(hours);
  return hr + ":" + minutes + " " + ampm;
}

//MM/dd/yyyy hh:mm tt
export function formatDateTimeForApi(
  date: Date,
  isStartTime: boolean = false,
  isEndTime: boolean = false,
) {
  if (!date) return undefined;
  let formattedDateOnly = [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join("/");

  if (!isStartTime && !isEndTime) {
    const time = getTimeInAMAndPM(date);
    return formattedDateOnly + " " + time;
  } else if (isStartTime) {
    return formattedDateOnly + " " + "00:00 AM";
  } else if (isEndTime) {
    return formattedDateOnly + " " + "11:59 PM";
  }
}

/**
 * Get date in dd-mm-yyyy format
 * @param date string
 * @returns
 */
export function formatStringDate(date: string) {
  if (!date) return;
  const dateCollection = date.split("-");
  return dateCollection[0].length === 4
    ? dateCollection.reverse().join("-")
    : date;
}

export function sumOfTimes(times: string[]) {
  let hours: number = 0;
  let minutes: number = 0;
  times?.forEach((item) => {
    if (item) {
      const collection = item?.split(":");
      hours += +collection[0];
      minutes += +collection[1];
      const { h, m } = convertNumberToTime(minutes);
      hours += h;
      minutes = m;
    }
  });
  return (
    (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes
  );
}

export function getTimeFormat(date: string | undefined) {
  if (!date) {
    return null;
  }
  const formatDate = new Date(date);
  const hours = formatDate.getUTCHours();
  const minutes = formatDate.getUTCMinutes();
  return (
    (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes
  );
}
export function formatDateForDateControl(date: string | Date) {
  //@ts-ignore
  let mdate = moment(date).local().format('YYYY-MM-DD');
  return mdate;
}

export function formatDateTimeForDateTimeControl(date: string) {
  if (date.includes("T") && !date.includes("GMT")) return date;

  let val = new Date(date);
  //@ts-ignore
  let formattedDate = [
    val.getFullYear(),
    padTo2Digits(val.getMonth() + 1),
    padTo2Digits(val.getDate()),
  ].join("-");
  formattedDate =
    formattedDate +
    "T" +
    padTo2Digits(val.getHours()) +
    ":" +
    padTo2Digits(val.getMinutes());
  return formattedDate;
}

//TODO: Convert local to array list.(REMOVE this)
export function getDateOnTheBasisOfZone(date: string) {
  let isEstZone = new Date().toString().includes("Eastern Daylight Time");
  let isCentralZone = new Date().toString().includes("Central Daylight Time");
  let isMountainZone = new Date().toString().includes("Mountain Daylight Time");
  let isWestZone = new Date().toString().includes("Pacific Daylight Time");
  let isAlaskaZone = new Date().toString().includes("Alaska Daylight Time");
  return (isEstZone ||
    isCentralZone ||
    isMountainZone ||
    isWestZone ||
    isAlaskaZone) &&
    !date.toString().includes("GMT")
    ? date.toString().replace(/-/g, "/")
    : date;
}

function convertNumberToTime(num: number): { h: number; m: number } {
  let hours = Math.floor(num / 60);
  let minutes = num % 60;
  // if (minutes + ''.length < 2) {
  //   minutes = '0' + minutes;
  // }
  return { h: hours, m: +minutes };
}

export function isSuperAdmin(
  userRole: FeamEntities.ID407userRole | undefined,
): boolean {
  return userRole?.roleTypeIds?.includes(userRoleType.supperAdmin)
    ? true
    : false;
}

export function isStationAdmin(
  userRole: FeamEntities.ID407userRole | undefined,
): boolean {
  return userRole?.roleTypeIds?.includes(userRoleType.stationAdmin)
    ? true
    : false;
}

export function isStationApprover(
  userRole: FeamEntities.ID407userRole | undefined,
) {
  return userRole?.roleTypeIds?.includes(userRoleType.stationApprover)
    ? true
    : false;
}

export function isBillingApprover(
  userRole: FeamEntities.ID407userRole | undefined,
) {
  return userRole?.roleTypeIds?.includes(userRoleType.billingApprover)
    ? true
    : false;
}
export function isPowerUser(
  userRole: FeamEntities.ID407userRole | undefined,
) {
  return userRole?.roleTypeIds?.includes(userRoleType.powerUser)
    ? true
    : false;
}

export function sumOf(collection: number[] | undefined) {
  if (!collection || collection.length === 0) return 0;
  return collection.reduce((accumulator: number, currentValue: number) => {
    return (+accumulator) + (+(currentValue ?? 0));
  }, 0);
}

//Trim all properties values
export function trimObjectProps(obj: any): boolean {
  try {
    Object.keys(obj).forEach((k) => {
      if (
        !(
          obj[k] &&
          Array.isArray(obj[k]) &&
          //@ts-ignore
          obj[k].length === 1 &&
          obj[k][0] === "on"
        )
      ) {
        if (obj[k] && Array.isArray(obj[k])) {
          obj[k].forEach((innerObject: any) => {
            trimObjectProps(innerObject);
          });
        }
        obj[k] = typeof obj[k] == "string" && obj[k] ? obj[k]?.trim() : obj[k];
      }
    });
    return true;
  }
  catch (err: any) {
    return false;
  }
}
