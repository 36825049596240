import { FieldArray, FieldArrayRenderProps } from "formik";
import { FeamFormArrayFieldInput, FeamFormArrayFieldSelect, FeamFormArrayTimeFieldInput, FeamFormArrayFieldAutoInputForEmployee } from "../../common";
import { FeamEntities, IProps } from "../../../models/feam-entities";

export default function CwsMechanicsAssignedWithOutWS(props: IProps.ICwsMechanicsAssignedWithOutWSProps<FeamEntities.IMechanicsAssigned>) {
    const { formProps, arraySelector, isPrinting } = props;
    return (
        <>
            {/*//@ts-ignore */}
            <div className={`row ${formProps.values.multiTail ? "" : "mb-1"}`}>
                <div className="col-6 d-flex flex-column ps-2">
                    <div className="row d-flex  d-inline-flex customer-worksheet-mgap ps-2">
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mname text-uppercase">NAME</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mtime text-uppercase">IN</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mtime text-uppercase">OUT</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mtime m-hours-width text-uppercase">Hours</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mselect m-select-width text-uppercase">Pay</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mselect m-select-width text-uppercase">Position</span>
                    </div>
                    <FieldArray
                        name={arraySelector}
                        render={(_: FieldArrayRenderProps) =>
                        (
                            <>
                                {/*//@ts-ignore */}
                                {formProps.values.mechanicsAssigneds?.map((m, index) => {
                                    return (
                                        ((index <= 11) &&
                                            <div key={`mechanicsAssingen_left_${index}`} className="row">
                                                <div className="d-flex  d-inline-flex customer-worksheet-mgap pe-0">
                                                    <div className="col-auto customer-worksheet-mname">
                                                        {/*//@ts-ignore */}
                                                        <FeamFormArrayFieldAutoInputForEmployee formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="mechanicName" className="customer-worksheet-form-control" data={[...props.mechanics]} />
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mtime">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].shiftStartTime)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftStartTime" onBlur={(e) => { setTimeDiff(e.target.value ?? 'hh:mm', formProps.values.mechanicsAssigneds[index].shiftEndTime ?? 'hh:mm', index); formProps.handleBlur(e) }} className="form-control" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].shiftStartTime && <input type="text" className="form-control customer-worksheet-form-control" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mtime">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].shiftEndTime)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftEndTime" onBlur={(e) => { setTimeDiff(formProps.values.mechanicsAssigneds[index]?.shiftStartTime ?? 'hh:mm', e.target.value ?? 'hh:mm', index); formProps.handleBlur(e) }} className="form-control" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].shiftEndTime && <input type="text" className="form-control customer-worksheet-form-control" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mtime m-hours-width">
                                                        <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftHours" className="customer-worksheet-form-tcontrol m-hours-width" readOnly={true} />
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mselect m-select-width">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].payCode)) && <FeamFormArrayFieldSelect defaultText="Pay" data={[...props.payTypes]} formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="payCode" className="customer-worksheet-form-control m-select-width" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].payCode && <input type="text" className="form-control customer-worksheet-form-control m-select-width" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mselect m-select-width">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].workTitleCode)) && <FeamFormArrayFieldSelect defaultText="Role" data={[...props.workTitles]} formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="workTitleCode" className="customer-worksheet-form-control m-select-width" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].workTitleCode && <input type="text" className="form-control customer-worksheet-form-control m-select-width" />}
                                                    </div>
                                                </div>
                                            </div>)
                                    )
                                })}
                            </>
                        )} />

                </div>
                <div className="col-6 d-flex flex-column ps-0">
                    <div className="row d-flex  d-inline-flex customer-worksheet-mgap ps-2">
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mname text-uppercase">NAME</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mtime text-uppercase">IN</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mtime text-uppercase">OUT</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mtime m-hours-width text-uppercase">Hours</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mselect inline-block m-select-width m-select-width text-uppercase">Pay</span>
                        <span className="col-auto d-flex  col-form-label fw-bold justify-content-center customer-worksheet-mselect m-select-width m-select-width text-uppercase">Position</span>
                    </div>
                    <FieldArray
                        name={arraySelector}
                        render={(_: FieldArrayRenderProps) =>
                        (
                            <>
                                {/*//@ts-ignore */}
                                {formProps.values.mechanicsAssigneds?.map((m, index) => {
                                    return (
                                        ((index >= 12) &&
                                            <div key={`mechanicsAssingen_left_${index}`} className="row">
                                                <div className="d-flex  d-inline-flex customer-worksheet-mgap ">
                                                    <div className="col-auto customer-worksheet-mname">
                                                        {/*//@ts-ignore */}
                                                        <FeamFormArrayFieldAutoInputForEmployee formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="mechanicName" className="customer-worksheet-form-control" data={[...props.mechanics]} />
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mtime">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].shiftStartTime)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftStartTime" onBlur={(e) => { setTimeDiff(e.target.value ?? 'hh:mm', formProps.values.mechanicsAssigneds[index].shiftEndTime ?? 'hh:mm', index); formProps.handleBlur(e) }} className="form-control" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].shiftStartTime && <input type="text" className="form-control customer-worksheet-form-control" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mtime">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].shiftEndTime)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftEndTime" onBlur={(e) => { setTimeDiff(formProps.values.mechanicsAssigneds[index]?.shiftStartTime ?? 'hh:mm', e.target.value ?? 'hh:mm', index); formProps.handleBlur(e) }} className="form-control" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].shiftEndTime && <input type="text" className="form-control customer-worksheet-form-control" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mtime m-hours-width">
                                                        <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="shiftHours" className="customer-worksheet-form-tcontrol m-hours-width" readOnly={true} />
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mselect m-select-width">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].payCode)) && <FeamFormArrayFieldSelect defaultText="Pay" data={[...props.payTypes]} formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="payCode" className="customer-worksheet-form-control m-select-width" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].payCode && <input type="text" className="form-control customer-worksheet-form-control m-select-width" />}
                                                    </div>
                                                    <div className="col-auto customer-worksheet-mselect m-select-width">
                                                        {/*//@ts-ignore */}
                                                        {(!isPrinting || (isPrinting && formProps.values.mechanicsAssigneds[index].workTitleCode)) && <FeamFormArrayFieldSelect defaultText="Role" data={[...props.workTitles]} formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={index} feamSelector="workTitleCode" className="customer-worksheet-form-control m-select-width" />}
                                                        {/*//@ts-ignore */}
                                                        {isPrinting && !formProps.values.mechanicsAssigneds[index].workTitleCode && <input type="text" className="form-control customer-worksheet-form-control m-select-width" />}
                                                    </div>
                                                </div>
                                            </div>)
                                    )
                                })}
                            </>
                        )} />
                </div>
            </div>
        </>
    )

    function setTimeDiff(start: string, end: string, index: number) {
        if ((!start && !end) || (start == '' || end == '') || (start === "00:00" && end === "00:00") || (start.includes("hh") || start.includes("mm") || end.includes("hh") || end.includes("mm"))) {
            formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, "");
            return;
        }
        const startCollection = start?.split(":");
        const endCollection = end?.split(":");
        const startDate = new Date(0, 0, 0, +startCollection[0], +startCollection[1], 0);
        const endDate = new Date(0, 0, 0, +endCollection[0], +endCollection[1], 0);
        let diff = endDate.getTime() - startDate.getTime();
        let hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(diff / 1000 / 60);

        // If using time pickers with 24 hours format, add the below line get exact hours
        if (hours < 0)
            hours = hours + 24;

        const hm = (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
        formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, hm);
    }
}