import * as BootStrapIcon from 'react-bootstrap-icons';
import { IProps } from "../../../models/feam-entities";
import { feamOAuthInfo, feamUserInfo } from '../../../models/constants';

export default function CwsFooterControls(props: IProps.ICwsFooterControlsProps) {
    return (
        <>
            <button type="button" title="Save & Print" className={`btn btn-secondary ms-1 d-flex flex-inline align-items-center`} onClick={() => props.onSaveClick()}>  {props.saving && !props.d407Id ? (<span className='d-flex flex-inline m-0 p-0 '> <span className="spinner-grow spinner-grow-sm m-0 p-0 me-1" role="status" aria-hidden="true"></span>Saving...</span>) : <> <BootStrapIcon.Save size={16} />&nbsp;&&nbsp;<BootStrapIcon.Printer size={16} /></>}</button>
            <button type="button" title="Sign Out" className="btn btn-secondary ms-1 text-nowrap" onClick={() => signOutHandler()}><BootStrapIcon.BoxArrowRight size={16} /></button>
        </>
    )
    function signOutHandler() {
        if (window.confirm(`Are you sure! You want to Sign Out.`)) {
            props.instance.logoutRedirect({ account: props.instance.getActiveAccount() });
            localStorage.setItem(feamUserInfo, "");
            localStorage.setItem(feamOAuthInfo, "");
        }
    }
}