import { FeamFormAutoCompleteInput, FeamFormInput, FeamFormSelect } from "../../common";
import { FeamEntities, IDropdown, IProps } from "../../../models/feam-entities";
import { useEffect, useState } from "react";
import { customerService } from "../../../services";
import stationService from "../../../services/station-service";
import { useTokenState } from "../../../RequestInterceptors";
import { usePrint } from "../../../PageLayout";
import { isMobile, isIOS } from "react-device-detect";

const stationsIdsForSubStations = [108, 120];
export default function CwsGeneralSection(props: IProps.ICwsGeneralSectionProps<FeamEntities.ID407Data>) {
    const [dropdownData, setDropdownData] = useState<{ customers: IDropdown[], stations: IDropdown[], subStations: IDropdown[] }>({ customers: [], stations: [], subStations: [] });
    const { isPrint } = usePrint();
    const { formProps, maintenanceTypesForDropdown, maintenanceTypes } = props;
    const isUpdate = checkUpdate();
    const { isTokenSet } = useTokenState();
    useEffect(() => {
        if (
            isTokenSet &&
            (dropdownData.customers.length === 0 ||
                dropdownData.stations.length === 0)
        ) {
            const fetchData = async () => {
                const subStationList: IDropdown[] = [];
                subStationList.push(
                    ...[
                        { text: "Hangar 1", value: "Hangar 1" },
                        { text: "Hangar 2", value: "Hangar 2" },
                    ]
                );
                const result = await Promise.all([
                    customerService.getAll(),
                    stationService.getAll(),
                ]);
                setDropdownData({
                    customers: result[0],
                    stations: result[1],
                    subStations: subStationList,
                });
            };
            fetchData();
        }
    }, [isTokenSet]);

    return (
        <div className={`${formProps.values.multiTail ? (isPrint && isMobile && isIOS ? "mb-0 mt-0" : "mb-1") : "mb-2"} w-100`}>
            <div className="d-flex flex-row justify-content-between align-items-center w-100  ps-2 pe-2 gap-2 mb-1">
                <div className="d-flex  d-inline-flex align-items-center gap-1">
                    <label className="customer-worksheet-label col-form-label fw-bold text-uppercase">Date:</label>
                    <div style={{ width: "100px" }}><FeamFormInput type="date" formProps={formProps} feamSelector="entryDate" className="customer-worksheet-form-control" /></div>
                </div>
                <div className="d-flex  d-inline-flex align-items-center  gap-1">
                    <label className="customer-worksheet-label col-form-label fw-bold text-uppercase">Customer:</label>
                    <div style={{ width: (formProps.values.stationId && stationsIdsForSubStations.includes(+formProps.values.stationId)) ? "160px" : "190px" }}><FeamFormSelect data={[...dropdownData.customers]} formProps={formProps} feamSelector="customerId" className="customer-worksheet-form-select form-select" defaultText="Select Customer" /></div>
                </div>
                {(props.isDynamic === undefined || (props.isDynamic && !props.isDynamic)) &&
                    <div className="d-flex  d-inline-flex align-items-center  gap-1" >
                        <label className="customer-worksheet-label col-form-label fw-bold text-uppercase text-nowrap ">MULTI-TAIL:</label>
                        <div className="form-check"><input id="multiTail" name="multiTail" className="form-check-input text-uppercase" type="checkbox" checked={formProps.values.multiTail ?? false} onChange={formProps.handleChange} disabled={isUpdate} /></div>
                    </div>
                }
                <div className="d-flex  d-inline-flex align-items-center gap-1">
                    <label className="customer-worksheet-label col-form-label fw-bold text-uppercase text-nowrap">
                        {/* STATION: */}
                        {!formProps.values.maintenanceTypeId ? "STATION:" : maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId?.toString())?.stationType + ":"}
                    </label>
                    <div style={{ width: formProps.values.maintenanceTypeId?.toString() === "9" && formProps.values.stationId && stationsIdsForSubStations.includes(+formProps.values.stationId) ? "100px" : formProps.values.stationId && stationsIdsForSubStations.includes(+formProps.values.stationId) ? "130px" : "170px" }}>
                        <FeamFormSelect data={[...dropdownData.stations]} formProps={formProps} feamSelector="stationId" className="customer-worksheet-form-select form-select" defaultText="Select Station" onChange={stationChangeHandler} />
                    </div>
                </div>
                {formProps.values.stationId && stationsIdsForSubStations.includes(+formProps.values.stationId) && <div className="d-flex  d-inline-flex align-items-center  gap-1">
                    <label className="customer-worksheet-label col-form-label fw-bold text-uppercase text-nowrap" >
                        SUB-STATION:
                    </label>
                    <div style={{ width: formProps.values.maintenanceTypeId?.toString() === "9" ? "100px" : "130px" }}> <FeamFormAutoCompleteInput data={[...dropdownData.subStations]} formProps={formProps} feamSelector="subStationId" className="customer-worksheet-form-auto-control" placeholder="--Select/Add Sub-Station--" /></div>
                </div>}
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center w-100  ps-2 pe-2 gap-4">
                <div className={`d-flex   d-inline-flex align-items-center  gap-3`}>
                    <label className="customer-worksheet-label col-form-label fw-bold text-uppercase text-nowrap customer-worksheet-gen-label-width">Maintenance:</label>
                    <div style={{ width: "204px" }}><FeamFormSelect defaultText="Select Type" data={[...maintenanceTypesForDropdown]} formProps={formProps} feamSelector="maintenanceTypeId" className="customer-worksheet-form-select form-select" onChange={maintenanceTypeChangeHandler} /></div>
                </div>
                <div className="d-flex d-inline-flex align-items-center gap-1 ">
                    <label className="customer-worksheet-label col-form-label fw-bold text-uppercase text-nowrap" >Operation Type:</label>
                    <div className="d-flex justify-content-between gap-2">
                        <div className="form-check d-inline-flex">
                            {/*//@ts-ignore */}
                            <input type="checkbox" id="cargoOperation" name="cargoOperation" checked={formProps.values.operationType === "Cargo"} onChange={(e) => {
                                formProps.setFieldValue('operationType', e.target.checked ? 'Cargo' : undefined);
                                formProps.handleChange(e);
                            }} className="form-check-input" />
                            <label className="customer-worksheet-label form-check-label ps-1 pt-1" >
                                Cargo
                            </label>
                        </div>
                        <div className="form-check d-inline-flex">
                            {/*//@ts-ignore */}
                            <input type="checkbox" id="passengerOperation" name="passengerOperation" checked={formProps.values.operationType === "Passenger"} onChange={(e) => {
                                formProps.setFieldValue('operationType', e.target.checked ? 'Passenger' : undefined);
                                formProps.handleChange(e);
                            }} className="form-check-input" />
                            <label className="customer-worksheet-label form-check-label ps-1 pt-1" >
                                Passenger
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`d-flex   d-inline-flex align-items-center gap-2 `}>
                    <label className="customer-worksheet-label col-form-label fw-bold text-uppercase">JOB#:</label>
                    <FeamFormInput formProps={formProps} feamSelector="aogOrOssNumber" className="customer-worksheet-form-control" style={{ width: !props.isDynamic ? "208px" : "210px" }} />
                </div>

            </div>
        </div>
    );

    function stationChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        if (stationsIdsForSubStations.includes(+e.target.value)) {
            formProps.setFieldValue("subStationId", null);
        }
        props.formProps.handleChange(e);
    }

    function checkUpdate() {
        return (formProps.values.d407id && formProps.values.d407id > 0) ? true : false
    }
    function maintenanceTypeChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        if (maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === e.target.value)?.name?.trim() === "FLT-Flight Mechanic") {
            formProps.setFieldValue("isWorkShift", true);
        }
        else {
            formProps.setFieldValue("isWorkShift", maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === e.target.value)?.isJobNumberType);
        }
        formProps.handleChange(e);
    }
}