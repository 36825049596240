import { FeamEntities } from "../models/feam-entities";
import apiClient from "./http-common";

class TimeAndLaborService {
  #rootPath: string = "timeandlabor";
  async getByFilter(
    filter: FeamEntities.IWorkSheetFilter,
  ): Promise<FeamEntities.ITimeAndLabor[]> {
    let data: FeamEntities.ITimeAndLabor[] = [];
    try {
      const response = await apiClient.get<FeamEntities.ITimeAndLabor[]>(
        `${this.#rootPath}/search`,
        {
          params: filter,
        },
      );
      return response.data;
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return data;
  }
}

const timeAndLaborService = new TimeAndLaborService();
export default timeAndLaborService;
