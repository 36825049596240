import { useEffect, useState } from "react";
import { usePrint } from "../../../PageLayout"
import { FeamEntities } from "../../../models/feam-entities";
import { CwsAdvancedSearchSection, CwsGeneralHeader, NoRecordFound } from "../common";
import { customerWorksheetService } from "../../../services";
import { searchType } from "../../../models/constants";
import { isMobile, isIOS } from "react-device-detect";
import SummaryReport from "./summary-report-new/SummaryReport2";
import { getSummaryReportFormatData } from "./summary-report-new/summary-report-util2";

export default function ReportContainer() {
    const [reportData, setReportData] = useState<FeamEntities.ISummaryReport[]>([]);
    const [searching, setSearching] = useState(false);
    const { isPrint, setIsPrint } = usePrint();
    useEffect(() => {
        if (isPrint) {
            setTimeout(() => {
                printHandler();
            }, 700);
        }
    }, [isPrint])
    return (
        <div className={`${!isPrint ? "container cws-advance-search feam-m-5" : ""}`} >
            <div style={{ display: isPrint ? "none" : "block" }}>
                <CwsGeneralHeader title="Report Search" />
                <CwsAdvancedSearchSection onSearch={searchHandler} onClear={() => { setReportData([]) }} searching={searching} maintenanceTypesForDropdown={[]} searchType={searchType.reports} />
                {reportData.length === 0 && <NoRecordFound message={"No Data found"} />}
            </div>
            {reportData.length > 0 && <SummaryReport reportData={reportData} />}
        </div>

    )

    async function searchHandler(filter: FeamEntities.IWorkSheetFilter) {
        if (reportData.length > 0) {
            alert('Please clear data before new search.');
            return;
        }
        if (!filter.weekNumber && !filter.startDate && !filter.endDate) {
            alert('Please select week numbers or start date and end date range.');
            return;
        }

        if (filter.weekNumber && (filter.startDate || filter.endDate)) {
            alert('Please select any one option the week numbers or start date and end date range.');
            return;
        }

        if (!filter.startDate && filter.endDate) {
            alert('Please select start date.');
            return;
        }

        if (!filter.customerId || !filter.stationId) {
            alert('Please select customer and station. They are required fields.');
            return;
        }
        setSearching(true);
        const result = await customerWorksheetService.summaryReportOfCustomerWorksheet(filter);
        if (result.length === 0) {
            setReportData([]);
            setSearching(false);
            return;
        }
        const summaryReportData: FeamEntities.ISummaryReport[] = getSummaryReportFormatData(result);
        setReportData(summaryReportData);
        setSearching(false);
    }
    function printHandler() {
        window.addEventListener(isMobile && !isIOS ? "focus" : "afterprint", listener);
        window.print();
        return () => {
            window.removeEventListener(isMobile && !isIOS ? "focus" : "afterprint", listener);
        }
    }

    function listener(e: any) {
        setIsPrint(false);
    }
}