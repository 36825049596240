import { useState } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import {
  FeamFormInput,
  FeamFormTimeFieldInput,
} from "../../common";
import CswDynamicTailDescForm from "./CswDynamicTailDescForm";
import { aviationStackflightDataService } from "../../../services";
import { getTimeFormat } from "../../../models/feam-utils";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import * as BootStrapIcon from "react-bootstrap-icons";
import { flightApiLogHelper } from "../../../models/flightapilog-helper";

export default function CswDynamicTailForm(
  props: IProps.ICswDynamicTailFormProps<FeamEntities.ITailDetail>
) {
  const [isLoaded, setIsLoaded] = useState(true);
  const { formProps, maintenanceTypeId, maintenanceTypes, station, flightDate, airlineName, d407Id } = props;
  const [flightDataLoaded, setFlightDataLoaded] = useState(false);
  const [arrivalFlights, setArrivalFlights] = useState<any[]>([]);
  const [departureFlights, setDepartureFlights] = useState<any[]>([]);

  return (
    <>
      <LoadingOverlayWrapper className="feam-postion-fixed" active={!isLoaded} text="Loading Flight Data..." spinner>
        <fieldset
          className="customer-worksheet-tail-form"
          disabled={formProps.isSubmitting}
        >
          <div>
            <div className="mb-3">
              {formProps.errors.tail && formProps.touched.tail && (
                <div className="d-flex">
                  <span className="d-flex justify-content-start text-danger">
                    {formProps.errors.tail}
                  </span>
                </div>
              )}
              <div className="d-flex d-inline-flex customer-worksheet-mgap customer-worksheet-multiletail-gap text-uppercase">
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width">
                  TAIL
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width">
                  FLIGHT#
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
                  GATE
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width">
                  Date
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
                  ETA
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
                  ATA
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
                  ETD
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
                  ATD
                </div>
                <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-control-width">
                  GU
                </div>
              </div>
              <div className="d-flex d-inline-flex customer-worksheet-multiletail-gap mb-1">
                <div className="d-flex justify-content-start ">
                  <div className="d-flex d-inline-flex gap-1 customer-worksheet-multiletail-bg-control-width">
                    <FeamFormInput
                      formProps={formProps}
                      feamSelector="tail"
                      className="customer-worksheet-form-control"
                    />
                    <i
                      role="button"
                      title="Search"
                      className="ms-1"
                      onClick={() => {
                        searchTailHandler();
                      }}
                    >
                      <BootStrapIcon.Search size={16} />
                    </i>
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-multiletail-bg-control-width">
                    <FeamFormInput
                      formProps={formProps}
                      feamSelector="flightNum"
                      className="customer-worksheet-form-control"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-multiletail-control-width">
                    <FeamFormInput
                      formProps={formProps}
                      feamSelector="gateNum"
                      className="customer-worksheet-form-control"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-multiletail-bg-control-width">
                    <FeamFormInput
                      type="date"
                      formProps={formProps}
                      feamSelector="arrivalDate"
                      className="customer-worksheet-form-control"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-multiletail-control-width">
                    <FeamFormTimeFieldInput
                      type="text"
                      formProps={formProps}
                      feamSelector="eta"
                      onBlur={(e) => {
                        formProps.handleBlur(e);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-multiletail-control-width">
                    <FeamFormTimeFieldInput
                      type="text"
                      formProps={formProps}
                      feamSelector="ata"
                      onBlur={(e) => {
                        formProps.handleBlur(e);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-multiletail-control-width">
                    <FeamFormTimeFieldInput
                      type="text"
                      formProps={formProps}
                      feamSelector="etd"
                      onBlur={(e) => {
                        formProps.handleBlur(e);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-multiletail-control-width">
                    <FeamFormTimeFieldInput
                      type="text"
                      formProps={formProps}
                      feamSelector="atd"
                      onBlur={(e) => {
                        formProps.handleBlur(e);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-start ">
                  <div className="customer-worksheet-multiletail-control-width">
                    <FeamFormInput type="text" formProps={formProps} feamSelector="gu" className="customer-worksheet-form-control" />
                  </div>
                </div>
              </div>
              <div className="d-flex d-inline-flex align-items-center gap-2">
                <div className="form-check d-inline-flex gap-1">
                  {/*//@ts-ignore */}
                  <input
                    type="checkbox"
                    name="ron"
                    checked={formProps.values.ron ?? false}
                    onChange={formProps.handleChange}
                    className="form-check-input"
                  />
                  <label className="customer-worksheet-label form-check-label pt-1">
                    RON
                  </label>
                </div>
                <div className="form-check d-inline-flex gap-1">
                  {/*//@ts-ignore */}
                  <input
                    type="checkbox"
                    name="towing"
                    checked={formProps.values.towing ?? false}
                    onChange={formProps.handleChange}
                    className="form-check-input"
                  />
                  <label className="customer-worksheet-label form-check-label pt-1">
                    Towing
                  </label>
                </div>
              </div>
            </div>

            <div className="d-flex  d-flex justify-content-start align-items-center header-col w-100 p-1 fw-bold mb-2 rounded text-uppercase">
              <span className="text-uppercase fw-bold">Material</span>
            </div>
            <div className="d-flex d-inline-flex mb-3">
              <div className="d-flex flex-column">
                <div className="d-flex d-inline-flex customer-worksheet-mgap customer-worksheet-multiletail-gap">
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    APU
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    O1
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    O2
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    O3
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    O4
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    H1
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    H2
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    H3
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    H4
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    Ni
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-dynamic-tail-min-control-width">
                    Ox
                  </div>
                  <div className="d-flex col-form-label fw-bold justify-content-start customer-worksheet-multiletail-bg-control-width">
                    OTHER
                  </div>
                </div>
                <div className="d-flex d-inline-flex customer-worksheet-multiletail-gap mb-3">
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="apu"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="oilEngine1"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="oilEngine2"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="oilEngine3"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="oilEngine4"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="hydEngine1"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="hydEngine2"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="hydEngine3"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="hydEngine4"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="materialsNitrogen"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start ">
                    <div className="customer-worksheet-dynamic-tail-min-control-width">
                      <FeamFormInput
                        type="number"
                        min={0}
                        formProps={formProps}
                        feamSelector="materialsOxygen"
                        className="customer-worksheet-form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <FeamFormInput
                      formProps={formProps}
                      feamSelector="materialsOther"
                      className="customer-worksheet-form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <CswDynamicTailDescForm
              formProps={formProps}
              maintenanceTypeId={maintenanceTypeId}
              maintenanceTypes={maintenanceTypes}
            />
          </div>
        </fieldset>
        <Modal isOpen={flightDataLoaded} size="lg" centered={true}>
          <ModalHeader className="text-uppercase">
            Flight Data Selection
          </ModalHeader>
          <ModalBody className="p-3">
            <div className="ps-3  pe-3" style={{ minHeight: "200px" }}>
              <table className="table table-striped advanced-search-table">
                <thead>
                  <tr className="text-uppercase">
                    <th scope="col" className="p-0 text-start">Flight Number</th>
                    <th scope="col" className="p-0 text-start">ETA</th>
                    <th scope="col" className="p-0 text-start">ATA</th>
                    <th scope="col" className="p-0 text-center"></th>
                  </tr >
                </thead >
                <tbody>
                  {arrivalFlights?.map((item: any, index) => {
                    return (
                      <tr key={`${item.flight.number}_${index}`}>

                        {/** @ts-ignore */}
                        <td className=" text-start">{item.flight.number}</td>
                        {/** @ts-ignore */}
                        <td className=" text-start">{getTimeFormat(item.arrival.scheduled)}</td>
                        <td className=" text-start">{getTimeFormat(item.arrival.actual)}</td>
                        <td className="text-center text-nowrap gap-1">
                          <a className="link-offset-2 link-underline link-underline-opacity-100" href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setArrivalData(item);
                              if (departureFlights.length > 0) {
                                //@ts-ignore
                                const tailValue = formProps.values.tail;
                                const selectedFlight = departureFlights.find(x => x.aircraft != null && x.aircraft.registration === tailValue);
                                if (selectedFlight) {
                                  setDepartureData(selectedFlight);
                                }
                              }
                              setFlightDataLoaded(false);
                            }}>Select</a></td>
                      </tr>)
                  })
                  }
                </tbody>
              </table ></div >
          </ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-outline-secondary" onClick={() => setFlightDataLoaded(false)}>Close</button >
          </ModalFooter>
        </Modal>
      </LoadingOverlayWrapper>
    </>
  );
  async function searchTailHandler() {
    if (!formProps.values.d407id) {
      //@ts-ignore
      const tailValue = formProps.values.tail ?? '';
      if (!tailValue || !station || !airlineName || !flightDate) {
        alert('Tail, Flight Date, Customer and Station are required to fetch flight info.');
        return false;
      }
      setIsLoaded(false);

      const response = await Promise.all([aviationStackflightDataService.getByArrivalStation(station, airlineName, flightDate, tailValue), aviationStackflightDataService.getByDepartureStation(station, airlineName, flightDate, tailValue)]);
      //@ts-ignore
      const arrivalData=response[0]?.flightData?? [];
      const arrivalUrl=response[0]?.url;
      const departureData=response[1]?.flightData?? [];
      const departureUrl=response[1]?.url;
       //@ts-ignore
      await flightApiLogHelper(arrivalData, departureData, formProps.values.customerId, formProps.values.stationId, formProps.values.tailDetails[index].tail, flightDate,arrivalUrl,departureUrl);
      if (arrivalData.length === 0) {
        alert("No flight data");
      }
      if (arrivalData.length === 1) {
        setArrivalData(arrivalData[0]);
        if (departureData.length > 0) {
          setDepartureData(departureData[0]);
        }
      }
      else if (arrivalData.length > 1) {
        setArrivalFlights(arrivalData);
        if (departureData.length > 1) {
          setDepartureFlights(departureData);
        }
        setFlightDataLoaded(true);
      }
      setIsLoaded(true);
    }
  }

  function setDepartureData(response: FeamEntities.IAviationStackFlightData) {
    formProps.setFieldValue(`etd`, getTimeFormat(response.departure.scheduled));
    formProps.setFieldValue(`atd`, getTimeFormat(response.departure.actual));
  }

  function setArrivalData(response: FeamEntities.IAviationStackFlightData) {
    formProps.setFieldValue(`flightNum`, response.flight.number);
    formProps.setFieldValue(`gateNum`, response.arrival.gate);
    formProps.setFieldValue(`eta`, getTimeFormat(response.arrival.scheduled));
    formProps.setFieldValue(`ata`, getTimeFormat(response.arrival.actual));
  }
}
