import { useEffect, useState } from "react";
import { FeamEntities, IDropdown } from "../../../models/feam-entities";
import { useTokenState } from "../../../RequestInterceptors";
import { CwsAdvancedSearchSection, CwsGeneralHeader } from "../common";
import "./BillingReview.css";
import { NoRecordFound } from "../common";
import { maintenanceTypeService, cobblestoneContractsActiveService, cobblestoneContractsBillingInfoService, cobblestoneContractsServiceTypeService, coreBillingService } from "../../../services";
import { sumOf } from "../../../models/feam-utils";
import ContractsInfo from "./ContractsInfo";
import billingTailMaterialDataService from "../../../services/billingtailmaterialsdata-service";
import BillingReviewWorkDetail from "./BillingReviewWorkDetail";
import { useFeamServiceType } from "../../hooks";
import { useBetween } from "use-between";
import TailMaterialDetailView from "./TailMaterialDetailView";
import { searchType } from "../../../models/constants";
import HourTailSummaryContainer from "./HourTailSummaryContainer";
import ServiceBillingInfoContainer from "./ServiceBillingInfoContainer";

export const useServiceType = () => useBetween(useFeamServiceType);

export default function BillingReviewContainer() {
  const { serviceType, setServiceType } = useServiceType();
  const [data, setData] = useState<FeamEntities.ID407BillingReview>();
  const [generalData, setGeneralData] = useState<{
    activeContracts: FeamEntities.ICobblestoneContractsActive[];
    billingInfos: FeamEntities.ICobblestoneContractsBillingInfo[];
    serviceTypes: FeamEntities.IContractServiceType[];
    tailMaterialData: FeamEntities.ITailMaterialsData[];
    tailMaterialDetail: FeamEntities.IBillingTailMaterialsData[];
  }>({ activeContracts: [], billingInfos: [], serviceTypes: [], tailMaterialData: [], tailMaterialDetail: [] });
  const [searching, setSearching] = useState(false);
  const [maintenanceTypeData, setMaintenanceTypeData] = useState<{
    maintenanceTypes: FeamEntities.IMaintenanceType[];
    maintenanceTypesDropdown: IDropdown[];
  }>({ maintenanceTypes: [], maintenanceTypesDropdown: [] });
  const { isTokenSet } = useTokenState();

  useEffect(() => {
    if (isTokenSet && maintenanceTypeData.maintenanceTypes.length === 0) {
      const fetchData = async () => {
        const result = await maintenanceTypeService.getAll();
        const dropdownData = result.map<IDropdown>(
          (d: FeamEntities.IMaintenanceType): IDropdown => {
            return { text: d.name, value: d.maintenanceTypeId?.toString() };
          }
        );
        setMaintenanceTypeData({
          maintenanceTypes: result,
          maintenanceTypesDropdown: dropdownData,
        });
      };
      fetchData();
    }
  }, [isTokenSet]);
  return (
    <div className="container cws-advance-search feam-m-5 w-100">
      <CwsGeneralHeader title="Billing Review" />
      <CwsAdvancedSearchSection onSearch={searchHandler} onClear={advanceSearchClearHandler} searching={searching} maintenanceTypesForDropdown={maintenanceTypeData.maintenanceTypesDropdown} searchType={searchType.billedReview} />
      {!data && <NoRecordFound message={"No Data found"} />}
      {data && (
        <div className="ps-3 pe-3 d-grid gap-3">
          <div className="d-flex d-flex-row w-100 gap-3 ">
            <div className="border rounded mb-3 w-100">
              <div className="d-flex justify-content-between align-items-center header-col w-100 p-2 fw-bold rounded text-uppercase">
                <span>Contracts Info</span>
              </div>
              <div style={{ minHeight: "50px" }}>
                {generalData.activeContracts.length === 0 && (
                  <NoRecordFound message={"No Data found"} />
                )}
                {generalData.activeContracts.length > 0 && (
                  <ContractsInfo contractsInfos={generalData.activeContracts ?? []} />
                )}
              </div>
            </div>
          </div>
          <ServiceBillingInfoContainer serviceTypes={generalData.serviceTypes} billingInfos={generalData.billingInfos} />
          <div className="pt-0 d-grid gap-3">
            <HourTailSummaryContainer d407BillingReview={data} billingReviewData={[...(data.billingReviewData ?? [])]} tailMaterialsData={[...(generalData.tailMaterialDetail ?? [])]} tailMaterialDetailsData={[...(generalData.tailMaterialData ?? [])]} />
            <BillingReviewWorkDetail billingReviewData={[...(data.billingPerHourData ?? [])]} />
            <TailMaterialDetailView tailMaterialsData={[...(generalData.tailMaterialDetail ?? [])]} />
          </div>
        </div>
      )}
    </div>
  );

  function advanceSearchClearHandler() {
    setServiceType(undefined);
    setData(undefined);
  }

  async function searchHandler(filter: FeamEntities.IWorkSheetFilter) {
    if (((!filter.weekNumber ||
      filter.weekNumber.length === 0) && (!filter.startDate || !filter.endDate)) ||
      !filter.customerId ||
      !filter.stationId) {
      alert(
        "Please select week number or date range, customer and station. All are required fields."
      );
      return;
    }
    setSearching(true);

    let reviewData;
    const [billingDiscountData, billingPerHourData, contracts, billings, services, tailMaterialData] =
      await Promise.all([
        coreBillingService.searchCoreBillingFlatRateData(filter),
        coreBillingService.searchCoreBillingPerHourData(filter),
        cobblestoneContractsActiveService.searchCobblestoneContractsActive(
          filter
        ),
        cobblestoneContractsBillingInfoService.searchCobblestoneContractsBillingInfo(
          filter
        ),
        cobblestoneContractsServiceTypeService.searchCobblestoneContractsServiceType(
          filter
        ),
        billingTailMaterialDataService.searchBillingTailMaterialData(filter)
      ]);

    let isPerHoursBilling = false;
    if (billingDiscountData.length === 0 && billingPerHourData.length > 0) {
      isPerHoursBilling = true;
    }

    const billingDetail: FeamEntities.ID407WorkTitleBillingDetail[] = [];
    //@ts-ignore next-line
    const groupBybillingData = Object.groupBy([...(!isPerHoursBilling ? billingDiscountData : billingPerHourData)], (x: FeamEntities.ICoreBillingFlatRateData | FeamEntities.ICoreBillingPerHourData) => {
      //@ts-ignore next-line
      return !isPerHoursBilling ? x.maintenanceType ?? "Others" : x.workTitleName;
    });

    const weekNums: string[] = []
    const extraServices: FeamEntities.ICoreBillingFlatRateData[] = [];
    Object.keys(groupBybillingData).forEach((key) => {
      const items = groupBybillingData[key] ?? [];

      if (items.length > 0) {
        const totalHours = items.map(
          (m: (FeamEntities.ICoreBillingFlatRateData | FeamEntities.ICoreBillingPerHourData)) => {
            if (!weekNums.find(x => x == m.weekNum)) weekNums.push(m.weekNum);
            if (!isPerHoursBilling) {
              //@ts-ignore next-line
              if (+(m.maxHoursPerService ?? 0) < +m.currentHours) {
                //@ts-ignore next-line
                extraServices.push(m)
              }
              //Retrun per service.
              return 1;
            }
            else {
              return m.currentHours;
            }
          }
        );
        const weekNumberAndHours = items.map(
          //1 retrun for per service
          (m: FeamEntities.ICoreBillingFlatRateData | FeamEntities.ICoreBillingPerHourData) => m.weekNum + "|" + (!isPerHoursBilling ? 1 : m.currentHours)
        );
        const extendedPrice: number[] = items.map(
          (m: FeamEntities.ICoreBillingFlatRateData | FeamEntities.ICoreBillingPerHourData) =>
            +(+(m.rateUSD ?? "0")).toFixed(2)
        );
        billingDetail.push({
          workTitle: key,
          //@ts-ignore next-line
          totalHoursOrItem: sumOf(totalHours),
          totalExtendedPrice: sumOf(extendedPrice),
          weekNumberAndHoursOrItem: weekNumberAndHours,
        });
      }
    });

    //@ts-ignore next-line
    const groupByExtraServices = Object.groupBy([...extraServices], (x: FeamEntities.ICoreBillingFlatRateData) => {
      return x.maintenanceType;
    });


    Object.keys(groupByExtraServices).forEach((key) => {
      //@ts-ignore next-line
      const extraServiceItems: FeamEntities.ICoreBillingFlatRateData[] = groupByExtraServices[key];
      if (extraServiceItems.length > 0) {
        const perHourItem = billingPerHourData.find(x => x.d407Id === extraServiceItems[0].d407Id);
        if (perHourItem) {
          const workTitle = perHourItem.workTitleName;
          const totalHours = extraServiceItems.map(m =>['Training'].includes(key)?+(m.currentHours) : +(m.currentHours) - +(m.maxHoursPerService));
          const weekNumberAndHours = extraServiceItems.map(
            (m: FeamEntities.ICoreBillingFlatRateData) => m.weekNum + "|" + (['Training'].includes(key) ?+(m.currentHours) : (+(m.currentHours) - +(m.maxHoursPerService)))
          );
          const extendedPrice: number[] = extraServiceItems.map(
            (m: FeamEntities.ICoreBillingFlatRateData) => {
              let extraHours = (+(m.currentHours) - +(m.maxHoursPerService));
              return +(extraHours * +(perHourItem.rateUSD ?? "0")).toFixed(2);
            });
          const billingInfo = billingDetail.find(x => x.workTitle == workTitle);
          if (billingInfo) {
            //@ts-ignore next-line
            totalHours.push(billingInfo.totalHoursOrItem);
            //@ts-ignore next-line
            extendedPrice.push(billingInfo.totalExtendedPrice);
            //@ts-ignore next-line
            weekNumberAndHours.push(...billingInfo.weekNumberAndHoursOrItem);
            //@ts-ignore next-line
            billingInfo.totalHoursOrItem = sumOf(totalHours);
            billingInfo.totalExtendedPrice = sumOf(extendedPrice);
            billingInfo.weekNumberAndHoursOrItem = weekNumberAndHours;
          }
          else {

            billingDetail.push({
              workTitle: workTitle,
              //@ts-ignore next-line
              totalHoursOrItem: sumOf(totalHours),
              totalExtendedPrice: sumOf(extendedPrice),
              weekNumberAndHoursOrItem: weekNumberAndHours,
            });
          }
        }
      }
    });
    //Handle material
    //@ts-ignore next-line
    const groupMaterialData = Object.groupBy([...billingPerHourData], (x: FeamEntities.ICoreBillingPerHourData) => {
      //@ts-ignore next-line
      return x.weekNum;
    });

    const oilCollection: number[] = [];
    const oilRateCollection: number[] = []
    const oilWeekNumberAndItem: string[] = []

    const hydCollection: number[] = [];
    const hydRateCollection: number[] = []
    const hydWeekNumberAndItem: string[] = []

    const niCollection: number[] = [];
    const niRateCollection: number[] = []
    const niWeekNumberAndItem: string[] = []

    const oxCollection: number[] = [];
    const oxRateCollection: number[] = []
    const oxWeekNumberAndItem: string[] = []
    Object.keys(groupMaterialData).forEach((key: string) => {
      const items = groupMaterialData[key] ?? [];
      if (items.length > 0) {
        let currentD407Id: number;

        items.forEach((item: FeamEntities.ICoreBillingPerHourData) => {
          if (item.d407Id != currentD407Id) {

            //Oil
            if (item.totalOil > 0 && item.oilRateUSD > 0) {
              oilCollection.push(item.totalOil);
              oilRateCollection.push(item.oilRateUSD);
              oilWeekNumberAndItem.push(key + '|' + item.totalOil)
            }
            //Hyd
            if (item.totalHyd > 0 && item.hydRateUSD > 0) {
              hydCollection.push(item.totalHyd);
              hydRateCollection.push(item.hydRateUSD);
              hydWeekNumberAndItem.push(key + '|' + item.totalHyd)
            }
            //Nitrogen
            if (item.totalNitrogen > 0 && item.nitrogenRateUSD > 0) {
              niCollection.push(item.totalNitrogen);
              niRateCollection.push(item.nitrogenRateUSD);
              niWeekNumberAndItem.push(key + '|' + item.totalNitrogen)
            }
            //Oxygen
            if (item.totalOxygen > 0 && item.oxygenRateUSD > 0) {
              oxCollection.push(item.totalOxygen);
              oxRateCollection.push(item.oxygenRateUSD);
              oxWeekNumberAndItem.push(key + '|' + item.totalOxygen)
            }
            currentD407Id = item.d407Id;
          }

        });

      }

    });

    //Oil
    if (oilCollection.length > 0) {
      billingDetail.push({
        workTitle: "Oil",
        //@ts-ignore next-line
        totalHoursOrItem: sumOf(oilCollection),
        totalExtendedPrice: sumOf(oilRateCollection),
        weekNumberAndHoursOrItem: oilWeekNumberAndItem,
      });
    }
    //Hydrogen
    if (hydCollection.length > 0) {
      billingDetail.push({
        workTitle: "Hydrogen",
        //@ts-ignore next-line
        totalHoursOrItem: sumOf(hydCollection),
        totalExtendedPrice: sumOf(hydRateCollection),
        weekNumberAndHoursOrItem: hydWeekNumberAndItem,
      });
    }

    //Oxigen
    if (oxCollection.length > 0) {
      billingDetail.push({
        workTitle: "Oxigen",
        //@ts-ignore next-line
        totalHoursOrItem: sumOf(oxCollection),
        totalExtendedPrice: sumOf(oxRateCollection),
        weekNumberAndHoursOrItem: oxWeekNumberAndItem,
      });
    }

    //Nitrogen
    if (niCollection.length > 0) {
      billingDetail.push({
        workTitle: "Nitrogen",
        //@ts-ignore next-line
        totalHoursOrItem: sumOf(niCollection),
        totalExtendedPrice: sumOf(niRateCollection),
        weekNumberAndHoursOrItem: niWeekNumberAndItem,
      });
    }



    //@ts-ignore next-line
    const groupByTailMaterialData = Object.groupBy([...tailMaterialData], (x) => (x.tail ?? "").trim());
    const sumOfTailMaterialData: FeamEntities.ITailMaterialsData[] = [];
    Object.keys(groupByTailMaterialData).forEach((key) => {
      if (!key) return;
      sumOfTailMaterialData.push({
        tail: key,
        hydEngine1: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.hydEngine1 ?? 0)
          )
        ).toString(),
        hydEngine2: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.hydEngine2 ?? 0)
          )
        ).toString(),
        hydEngine3: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.hydEngine3 ?? 0)
          )
        ).toString(),
        hydEngine4: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.hydEngine4 ?? 0)
          )
        ).toString(),
        oilEngine1: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.oilEngine1 ?? 0)
          )
        ).toString(),
        oilEngine2: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.oilEngine2 ?? 0)
          )
        ).toString(),
        oilEngine3: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.oilEngine3 ?? 0)
          )
        ).toString(),
        oilEngine4: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.oilEngine4 ?? 0)
          )
        ).toString(),
        materialsOxygen: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.materialsOxygen ?? 0)
          )
        ).toString(),
        materialsNitrogen: sumOf(
          groupByTailMaterialData[key]?.map(
            (x: FeamEntities.IBillingTailMaterialsData) => +(x.materialsNitrogen ?? 0)
          )
        ).toString(),
        towing: groupByTailMaterialData[key]?.find(
          (x: FeamEntities.IBillingTailMaterialsData) => x.towing === true
        )
          ? 1
          : 0,
      });
    });

    const d407BillingReviews: FeamEntities.ID407BillingReview = {
      stationId: filter.stationId,
      customerId: filter.customerId,
      weekNumber: weekNums.sort(),
      billingReviewData: reviewData,
      approvedBy: filter.approvedBy,
      workTitleBillingDetail: billingDetail,
      billingPerHourData: billingPerHourData
    };

    //@ts-ignore next-line
    const groupByContractServiceTypes = Object.groupBy([...services], (x) => {
      return (
        (x.cobblestoneContractServicesType ?? "").trim() +
        "|" +
        (x.cobblestoneArea ?? "").trim()
      );
    });
    const contractsServices: FeamEntities.IContractServiceType[] = [];
    Object.keys(groupByContractServiceTypes).forEach((key) => {
      const areaSplit = key.split("|");
      const ct: FeamEntities.ICobblestoneContractsServiceType[] =
        groupByContractServiceTypes[key] ?? [];
      const contractsServiceAreas: FeamEntities.IContractServiceTypeByArea[] =
        [];
      const contractsServiceTypes: FeamEntities.IContractServiceTypes[] = [];

      ct.forEach((contractType) => {
        contractsServiceTypes.push({
          cobblestoneServiceType: contractType.cobblestoneServiceType,
          cobblestoneRateUSD: contractType.cobblestoneRateUSD,
          hasBillingInfo: billings?.some(
            (s) =>
              s.cobblestoneServiceType === contractType.cobblestoneServiceType
          ),
        });
      });
      if (
        !contractsServiceAreas.find((s) => s.cobblestoneArea !== areaSplit[1])
      ) {
        contractsServiceAreas.push({
          cobblestoneArea: areaSplit[1].trim(),
          serviceTypes: contractsServiceTypes ?? [],
        });
      }
      const contractServiceType = contractsServices.find(
        (s) =>
          (!s.cobblestoneContractServicesType && areaSplit[0] === "") ||
          (s.cobblestoneContractServicesType &&
            s.cobblestoneContractServicesType.trim() === areaSplit[0])
      );
      if (!contractServiceType) {
        contractsServices.push({
          cobblestoneContractServicesType: areaSplit[0].trim(),
          areas: contractsServiceAreas,
        });
      } else {
        contractServiceType.areas?.push(...contractsServiceAreas);
      }
    });
    setData(d407BillingReviews);
    setServiceType(undefined);
    setGeneralData({
      activeContracts: contracts,
      billingInfos: billings,
      serviceTypes: contractsServices,
      tailMaterialData: sumOfTailMaterialData,
      tailMaterialDetail: tailMaterialData
    });
    setSearching(false);
  }
}
