import { useEffect } from "react";
import { pageTitle } from "../../models/constants";
import "./FeamMain.css";
import ReportContainer2 from "../customer-worksheet/reports copy/ReportContainer2";

export default function ReportPage2() {
  useEffect(() => { document.title = pageTitle.reports }, [])
  return (
    <ReportContainer2 />
  )
}