import {
  aogPay,
  avionicsType,
  extraHoursPay,
  flightMechanicPay,
  leadsTypes,
  mechanicsTypes,
  overtimePay,
  perProjectPay,
  regularPay,
  reportAog,
  reportAssistant,
  reportAvionicsType,
  reportEngineer,
  reportFlightMechanic,
  reportInspector,
  reportLeadsTypes,
  reportMechanicHelper,
  reportMechanicsTypes,
  reportOss,
  reportPlanner,
  reportSpecialist,
  reportSupervisorsTypes,
  workSupportPay,
  workTitles,
} from "../../../../models/constants";
import { FeamEntities } from "../../../../models/feam-entities";
import * as factories from "../../../../models/factories";
import { formatStringDate, sumOfTimes } from "../../../../models/feam-utils";

export function getSummaryReportFormatData(
  data: FeamEntities.ID407Data[],
): FeamEntities.ISummaryReport[] {
  const summaryReportData: FeamEntities.ISummaryReport[] = [];
  //@ts-ignore next-line
  const groupByWeekNumber = Object.groupBy(
    [...data],
    //@ts-ignore next-line
    (x: FeamEntities.ID407Data) => x.weekNumber,
  );
  Object.keys(groupByWeekNumber).forEach((key: string) => {
    const d407Data: FeamEntities.ID407Data[] = groupByWeekNumber[key] ?? [];
    if (d407Data && d407Data.length > 0) {
      const summaryReport: FeamEntities.ISummaryReport =
        factories.getSummaryReport();

      const tailNumbers = d407Data
        .flatMap((x) => x.tailDetails?.flatMap((x) => x.tail?.trim()))
        .filter((x) => x);
      //@ts-ignore next-line
      const groupByTailNumber = Object.groupBy([...tailNumbers], (x: string) =>
        x.trim(),
      );
      summaryReport.tailNumbers = groupByTailNumber;
      //set command data
      //@ts-ignore next-line
      summaryReport.weekAndDate = `${key} ${formatStringDate(
        //@ts-ignore next-line
        d407Data[0].startDateOfWeek.toString().split("T")[0],
      )}-${formatStringDate(
        //@ts-ignore next-line
        d407Data[0].endDateOfWeek.toString().split("T")[0],
      )}`;
      summaryReport.customer = d407Data[0].customer;
      summaryReport.stationId = d407Data[0].stationId;
      summaryReport.stationCode = d407Data[0].stationCode;
      summaryReport.repairStation = d407Data
        ?.map((x: FeamEntities.ID407Data) => x.repairStation ?? false)
        .includes(true);
      summaryReport.airCarrier = d407Data
        ?.map((x: FeamEntities.ID407Data) => x.airCarrier ?? false)
        .includes(true);
      summaryReport.apu = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.apu ?? "0"),
        ),
      ).toString();
      //oil
      summaryReport.o1 = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.oilEngine1 ?? "0"),
        ),
      );
      summaryReport.o2 = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.oilEngine2 ?? "0"),
        ),
      );
      summaryReport.o3 = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.oilEngine3 ?? "0"),
        ),
      );
      summaryReport.o4 = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.oilEngine4 ?? "0"),
        ),
      );
      //hydrogen
      summaryReport.h1 = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.hydEngine1 ?? "0"),
        ),
      );
      summaryReport.h2 = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.hydEngine2 ?? "0"),
        ),
      );
      summaryReport.h3 = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.hydEngine3 ?? "0"),
        ),
      );
      summaryReport.h4 = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.hydEngine4 ?? "0"),
        ),
      );

      summaryReport.nitrogen = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.materialsNitrogen ?? "0"),
        ),
      ).toString();
      summaryReport.oxygen = getSumOfCollection(
        d407Data.flatMap((x: FeamEntities.ID407Data) =>
          x.tailDetails?.flatMap((t) => t.materialsOxygen ?? "0"),
        ),
      ).toString();
      const mechanicsAssigned: FeamEntities.IMechanicsAssignedOfSummaryReport[] =
        [];
      [
        workTitles.mechanics,
        workTitles.avionics,
        workTitles.supervisors,
        workTitles.leads,
        workTitles.assistant,
        workTitles.flightMechanic,
        workTitles.engineer,
        workTitles.specialist,
        workTitles.planner,
        workTitles.mechanicHelper,
        workTitles.aog,
        workTitles.oss,
        workTitles.inspector,
        workTitles.other,
      ].forEach((title) => {
        let mechanics: (FeamEntities.IMechanicsAssigned | undefined)[] = [];
        switch (title) {
          case workTitles.mechanics:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportMechanicsTypes),
            );
            break;
          case workTitles.avionics:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportAvionicsType),
            );
            break;
          case workTitles.supervisors:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportSupervisorsTypes),
            );
            break;
          case workTitles.leads:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportLeadsTypes),
            );
            break;
          case workTitles.assistant:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportAssistant),
            );
            break;
          case workTitles.flightMechanic:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportFlightMechanic),
            );
            break;
          case workTitles.engineer:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportEngineer),
            );
            break;
          case workTitles.specialist:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportSpecialist),
            );
            break;
          case workTitles.planner:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportPlanner),
            );
            break;
          case workTitles.mechanicHelper:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportMechanicHelper),
            );
            break;
          case workTitles.aog:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportAog),
            );
            break;
          case workTitles.oss:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportOss),
            );
            break;
          case workTitles.inspector:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              getMechanics(x, reportInspector),
            );
            break;
          case workTitles.other:
            mechanics = d407Data.flatMap((x: FeamEntities.ID407Data) =>
              x.mechanicsAssigneds?.filter(
                (f) =>
                  f.mechanicName &&
                  f.shiftHours &&
                  [...regularPay, ...overtimePay, ...workSupportPay, ...aogPay, extraHoursPay, ...flightMechanicPay, ...perProjectPay].includes(f.payCode ?? "") &&
                  ![
                    ...reportAog,
                    ...reportAssistant,
                    ...reportAvionicsType,
                    ...reportEngineer,
                    ...reportFlightMechanic,
                    ...reportInspector,
                    ...reportLeadsTypes,
                    ...reportMechanicHelper,
                    ...reportMechanicsTypes,
                    ...reportOss,
                    ...reportPlanner,
                    ...reportSpecialist,
                    ...reportSupervisorsTypes,
                  ].includes(f.workTitleCode?.trim() ?? ""),
              ),
            );
            break;
        }
        if (mechanics?.length > 0) {
          mechanicsAssigned.push({
            workTitle: title,
            detail: getMechanicsAssignedDetail(mechanics),
          });
        }
      });
      summaryReport.mechanicsAssigned = mechanicsAssigned;
      //add in summary report collection
      summaryReportData.push(summaryReport);
    }
  });
  return summaryReportData;
}

function getMechanics(d407Data: FeamEntities.ID407Data, types: string[]) {
  return d407Data.mechanicsAssigneds?.filter(
    (f) =>
      f.mechanicName &&
      f.shiftHours &&
      [...regularPay, ...overtimePay, ...workSupportPay, ...aogPay, ...extraHoursPay, ...flightMechanicPay, ...perProjectPay].includes(f.payCode ?? "") &&
      types.includes(f.workTitleCode?.trim() ?? ""),
  );
}

function getSumOfCollection(
  arr: (string | undefined)[],
  initial: number = 0,
): number {
  const result = arr.reduce((accumulator, currentValue) => {
    return (
      accumulator +
      //@ts-ignore next-line
      (currentValue === "" && isNaN(currentValue) ? 0 : +currentValue)
    );
  }, initial);
  return result;
}
function getMechanicsAssignedDetail(
  mechanics: (FeamEntities.IMechanicsAssigned | undefined)[],
) {
  const mDetail: FeamEntities.IMechanicsAssignedDetailOfSummaryReport[] = [];
  //@ts-ignore next-line
  const groupByMechanics = Object.groupBy(
    //@ts-ignore next-line
    [...mechanics.toSorted((x) => x?.mechanicName)],
    (x: FeamEntities.IMechanicsAssigned) =>
      x.mechanicName?.trim() + "|" + x.employeeCode?.trim() ?? "",
  );
  Object.keys(groupByMechanics).forEach((key: string) => {
    const mechanicDetail = groupByMechanics[key];
    if (mechanicDetail) {
      mDetail.push({
        name: mechanicDetail[0].mechanicName,
        reg: sumOfTimes(
          mechanicDetail
            .filter((x: FeamEntities.IMechanicsAssigned) =>
              regularPay.includes(x.payCode ?? ""),
            )
            .map(
              (m: FeamEntities.IMechanicsAssigned) => m.shiftHours ?? "00:00",
            ),
        ),
        ot: sumOfTimes(
          mechanicDetail
            .filter((x: FeamEntities.IMechanicsAssigned) =>
              overtimePay.includes(x.payCode ?? ""),
            )
            .map(
              (m: FeamEntities.IMechanicsAssigned) => m.shiftHours ?? "00:00",
            ),
        ),
        ws: sumOfTimes(
          mechanicDetail
            .filter((x: FeamEntities.IMechanicsAssigned) =>
              workSupportPay.includes(x.payCode ?? ""),
            )
            .map(
              (m: FeamEntities.IMechanicsAssigned) => m.shiftHours ?? "00:00",
            ),
        ),
        aog: sumOfTimes(
          mechanicDetail
            .filter((x: FeamEntities.IMechanicsAssigned) =>
              aogPay.includes(x.payCode ?? ""),
            )
            .map(
              (m: FeamEntities.IMechanicsAssigned) => m.shiftHours ?? "00:00",
            ),
        ),
        eh: sumOfTimes(
          mechanicDetail
            .filter((x: FeamEntities.IMechanicsAssigned) =>
              extraHoursPay.includes(x.payCode ?? ""),
            )
            .map(
              (m: FeamEntities.IMechanicsAssigned) => m.shiftHours ?? "00:00",
            ),
        ),
        fm: sumOfTimes(
          mechanicDetail
            .filter((x: FeamEntities.IMechanicsAssigned) =>
              flightMechanicPay.includes(x.payCode ?? ""),
            )
            .map(
              (m: FeamEntities.IMechanicsAssigned) => m.shiftHours ?? "00:00",
            ),
        ),
        pp: sumOfTimes(
          mechanicDetail
            .filter((x: FeamEntities.IMechanicsAssigned) =>
              perProjectPay.includes(x.payCode ?? ""),
            )
            .map(
              (m: FeamEntities.IMechanicsAssigned) => m.shiftHours ?? "00:00",
            ),
        ),
      });
    }
  });
  return mDetail;
}
