import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import * as BootStrapIcon from "react-bootstrap-icons";
import { IProps } from "../../../models/feam-entities";
import { processingStatusType } from "../../../models/constants";
import { useFeamAuth } from "../../hooks";
import * as utils from "../../../models/feam-utils";

export default function AdvancedSearchActionDropdown(
  props: IProps.IAdvancedSearchActionDropdownProps
) {
  const [actionToggle, setActionToggle] = useState(false);
  const { user } = useFeamAuth();

  return (
    <>
      <Dropdown
        isOpen={actionToggle}
        toggle={() => {
          //@ts-ignore
          if (props.selectedStatusIds && props.selectedStatusIds.length > 1) {
            setActionToggle(false);
            window.confirm(
              "Please select only one Status Type to perform any actions."
            );
          } else {
            setActionToggle((p) => !p);
          }
        }}
      >
        <DropdownToggle color="" title="Action">
          <BootStrapIcon.Gear size={16} />
        </DropdownToggle>

        <DropdownMenu>
        {getSelectedStatus() === processingStatusType.approved  && 
        <DropdownItem
              onClick={() => {
                props.onActionClick(
                  processingStatusType.administrative,
                  "Administrative"
                );
              }}
              disabled={disableApprove()}
            >
              <div className="d-inline-flex gap-3 align-items-center">
                <BootStrapIcon.BookmarkCheck
                  size={16}
                  className="text-info"
                />
                <span>Administrative</span>
              </div>
            </DropdownItem>
        }
          {getSelectedStatus() === processingStatusType.approved && (
            <DropdownItem
              onClick={() => {
                props.onActionClick(
                  processingStatusType.markedforBilling,
                  "Mark for Billing"
                );
              }}
              disabled={disableApprove()}
            >
              <div className="d-inline-flex gap-3 align-items-center">
                <BootStrapIcon.BookmarkCheck
                  size={16}
                  className="text-success"
                />
                <span>Mark for Billing</span>
              </div>
            </DropdownItem>
          )}

          {getSelectedStatus() === processingStatusType.markedforBilling && (
            <DropdownItem
              onClick={() => {
                props.onActionClick(
                  processingStatusType.approved,
                  "Un-Mark for Billing"
                );
              }}
            >
              <div className="d-inline-flex gap-3 align-items-center">
                <BootStrapIcon.BookmarkX size={16} className="text-warning" />
                <span>Un-Mark for Billing</span>
              </div>
            </DropdownItem>
          )}

          {!getSelectedStatus() && (
            <DropdownItem
              onClick={() => {
                props.onActionClick(processingStatusType.approved, "Approve");
              }}
              disabled={disableApprove()}
            >
              <div className="d-inline-flex gap-3 align-items-center">
                <BootStrapIcon.CheckCircle size={16} className="text-primary" />
                <span>Approve</span>
              </div>
            </DropdownItem>
          )}

          {(getSelectedStatus() === processingStatusType.approved ||
          getSelectedStatus() === processingStatusType.administrative) && (
            <DropdownItem
              onClick={() => {
                props.onActionClick(undefined, "Unapprove");
              }}
              disabled={disableApprove()}
            >
              <div className="d-inline-flex gap-3 align-items-center">
                <BootStrapIcon.XCircle size={16} className="text-danger" />
                <span>Unapprove</span>
              </div>
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              props.onActionClick(processingStatusType.delete, "Delete");
            }}
            disabled={disableApprove()}
          >
            <div className="d-inline-flex gap-3 align-items-center">
              <BootStrapIcon.Trash2Fill size={16} className="text-danger" />
              <span>Delete</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );

  function disableApprove(): boolean {
    return (
      !utils.isStationAdmin(user) &&
      !utils.isStationApprover(user) &&
      !utils.isSuperAdmin(user) &&
      !utils.isPowerUser(user)
    );
  }

  function getSelectedStatus(): number | undefined {
    //@ts-ignore
    if (props.selectedStatusIds && props.selectedStatusIds.length === 1) {
      //@ts-ignore
      return props.selectedStatusIds[0];
    } else {
      return undefined;
    }
  }
}
