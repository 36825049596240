import moment from "moment";
import { FeamEntities } from "./feam-entities";

function getD407Data(multiTail: boolean = false): FeamEntities.ID407Data {
  let date = getCurrentDate();
  return {
    entryDate: date,
    dateCompleted: date,
    stationId: 120,
    stationCode: "MIA",
    totalSum: "00:00",
    multiTail: multiTail,
    isDeleted: false,
    repairStation: false,
    airCarrier: false,
    isWorkShift: false,
    isApproved: false,
    mechanicsAssigneds: getmechanicsAssigneds(),
    personalExpenses: getPersonalExpenses(),
    tailDetails: getTailDetails(multiTail),
  };
}

function getPersonalExpenses(): FeamEntities.IPersonalExpense[] {
  return [{}];
}

function getCurrentDate() {
  return moment().format('YYYY-MM-DD');
}
function getD407DynamicData(): FeamEntities.ID407Data {
  let date = getCurrentDate();
  return {
    entryDate: date,
    dateCompleted: date,
    totalSum: "00:00",
    multiTail: true,
    isApproved: false,
    isDeleted: false,
    repairStation: false,
    airCarrier: false,
    mechanicsAssigneds: [],
    personalExpenses: [],
    tailDetails: [],
  };
}

function getmechanicsAssigneds(): FeamEntities.IMechanicsAssigned[] {
  const mechanicsAssigned: FeamEntities.IMechanicsAssigned[] = [];

  for (let index = 0; index < 24; index++) {
    mechanicsAssigned.push({
      mechanicName: "",
      payCode: "",
      shiftStartDate: "",
      shiftStartTime: "",
      shiftEndDate: "",
      shiftEndTime: "",
      workShift: undefined,
      workShiftTime: "",
      workTitleCode: "",
      shiftHours: "",
      employeeCode: "",
    });
  }
  return mechanicsAssigned;
}

function getTailDetails(multiTail: boolean) {
  const tails: FeamEntities.ITailDetail[] = [];
  for (let i = 0; i < (multiTail ? 10 : 1); i++) {
    tails.push(getTailDetail(multiTail));
  }
  return tails;
}
function getTailDetail(multiTail: boolean): FeamEntities.ITailDetail {
  return {
    towing: false,
    ron: false,
    workDetails: multiTail
      ? getWorkDetailForMultiTail()
      : getWorkDetailForSingleTail(),
  };
}
function getWorkDetailForSingleTail(): FeamEntities.IWorkDetail[] {
  return [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
}

function getWorkDetailForMultiTail(): FeamEntities.IWorkDetail[] {
  return [{ description: "" }];
}
function getSummaryReport(): FeamEntities.ISummaryReport {
  return { jobDescription: [], mechanicsAssigned: [] };
}

function getProcessing(): FeamEntities.IProcessing {
  return { processingId: 0 };
}

function getD407userRole(): FeamEntities.ID407userRole {
  return { d407userRoleStationMappers: [], d407userRoleTypeMappers: [{}] };
}

export {
  getD407Data,
  getWorkDetailForMultiTail,
  getWorkDetailForSingleTail,
  getTailDetails,
  getmechanicsAssigneds,
  getTailDetail,
  getD407DynamicData,
  getPersonalExpenses,
  getSummaryReport,
  getProcessing,
  getD407userRole,
};
