import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { CwsGeneralHeader, NoRecordFound } from "../common";
import { usePrint } from "../../../PageLayout";
import { useEffect, useState } from "react";
import { FeamEntities } from "../../../models/feam-entities";
import * as BootStrapIcon from "react-bootstrap-icons";
import { isIOS, isMobile } from "react-device-detect";
import {
  aviationStackflightDataService,
  customerService,
} from "../../../services";

export default function CustomerDataSyncContainer() {
  const [syncedCustomers, setSyncedCustomers] = useState<
    FeamEntities.ICustomer[]
  >([]);
  const [unmatchedCustomers, setUnmatchedCustomers] = useState<
    FeamEntities.ICustomer[]
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [showCustomers, setShowCustomers] = useState(false);

  return (
    <>
      <div className="container cws-advance-search feam-m-5">
        <div style={{ display: "block" }}>
          <CwsGeneralHeader title="Customer Data Sync" />
        </div>
        <div className="d-flex justify-content-between align-items-center header-col w-100 p-2 pt-1 pb-1 mb-3 fw-bold rounded text-uppercase ">
          <span>Customers Sync</span>
          <button
            type="button"
            className="btn btn-secondary text-uppercase feam-title-btn-padding justify-content-center align-items-center"
            onClick={() => {
              syncCustomers();
            }}
            title="Sync Customers"
            disabled={submitting}
          >
            {!submitting ? (
              <BootStrapIcon.ArrowRepeat size={16} />
            ) : (
              <>
                {" "}
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Syncing Customers...
              </>
            )}
          </button>
        </div>
        {showCustomers && (
          <div className="d-flex d-flex-row w-100 gap-3 ">
            <div className="w-50 border rounded">
              <div className="d-flex justify-content-between align-items-center header-col w-100 p-2 pt-1 pb-1 fw-bold rounded text-uppercase">
                <span>Updated Customers</span>
              </div>
              <div style={{ minHeight: "100px" }}>
                {syncedCustomers.length === 0 && (
                  <NoRecordFound message={"No Data found"} />
                )}
                {syncedCustomers.length > 0 && (
                  <div className="ps-3 pt-1">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th className="text-nowrap">Customer Name</th>
                          <th className="text-nowrap">Updated Fields</th>
                        </tr>
                      </thead>
                      <tbody>
                        {syncedCustomers?.map((h, i) => {
                          return (
                            <tr key={`updated_customer_${i}`}>
                              <td>{h.companyName}</td>
                              <td>{h.updatedFields}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="w-50 border rounded">
              <div className="d-flex justify-content-between align-items-center header-col w-100 p-2 pt-1 pb-1 fw-bold rounded text-uppercase">
                <span>Not Matched Customers</span>
              </div>
              <div style={{ minHeight: "100px" }}>
                {unmatchedCustomers.length === 0 && (
                  <NoRecordFound message={"No Data found"} />
                )}
                {unmatchedCustomers.length > 0 && (
                  <div className="ps-3 pt-1">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th className="text-nowrap">Customer Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {unmatchedCustomers?.map((h, i) => {
                          return (
                            <tr key={`notUpdated_customer_${i}`}>
                              <td>{h.companyName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );

  async function syncCustomers() {
    setSubmitting(true);
    setShowCustomers(false);
    const customers = await customerService.getCustomersToSync();
    const updatedCustomers: FeamEntities.ICustomer[] = [];
    for (const f of customers) {
      if (f.companyName) {
        const airlineResponse =
          await aviationStackflightDataService.getAirlinesByName(f.companyName);
        if (
          airlineResponse &&
          ((!f.airlineName && airlineResponse.airline_name) ||
            (!f.airlineCode && airlineResponse.iata_prefix_accounting) ||
            (!f.iatacode && airlineResponse.iata_code) ||
            (!f.icaocode && airlineResponse.icao_code) ||
            (!f.callSign && airlineResponse.callsign))
        ) {
          f.updatedFields =
          (!f.airlineName && airlineResponse.airline_name
            ? "Airline Name,"
            : "") +
          (!f.airlineCode && airlineResponse.iata_prefix_accounting
            ? "Airline Code,"
            : "") +
          (!f.iatacode && airlineResponse.iata_code ? "IATA Code," : "") +
          (!f.icaocode && airlineResponse.icao_code ? "ICAO Code," : "") +
          (!f.callSign && airlineResponse.callsign ? "CallSign" : "");

          f.airlineName = f.airlineName ?? airlineResponse.airline_name;
          f.airlineCode =
            f.airlineCode ?? airlineResponse.iata_prefix_accounting;
          f.iatacode = f.iatacode ?? airlineResponse.iata_code?.substring(0,10);
          f.icaocode = f.icaocode ?? airlineResponse.icao_code?.substring(0,20);
          f.callSign = f.callSign ?? airlineResponse.callsign?.substring(0,10);

          await customerService.update(f);
          updatedCustomers.push(f);
        }
      }
    }
    const notMatched = customers.filter((el) => !updatedCustomers.includes(el));
    setSyncedCustomers(updatedCustomers);
    setUnmatchedCustomers(notMatched);
    setShowCustomers(true);
    setSubmitting(false);
  }
}
