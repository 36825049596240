import { feamOAuthInfo } from "../models/constants";
import apiClient, { apiConfigMiddleWare } from "./http-common";

class OAuth2TokenForWebhookService {
  #rootPath: string = "oauth2tokenforwebhook";
  async getBearerToken(): Promise<boolean> {
    
    try {
      const oAuthTokenJsonObject= localStorage.getItem(feamOAuthInfo);
      const oAuthTokenObject=oAuthTokenJsonObject?JSON.parse(oAuthTokenJsonObject??""):null;
      if (!oAuthTokenJsonObject ||   (new Date(oAuthTokenObject?.expireTime) && new Date(oAuthTokenObject?.expireTime)<=new Date()) ) {
        const oAuthResponse = await apiClient.get<any>(
          `${this.#rootPath}/bearer`
        );
        localStorage.setItem(feamOAuthInfo, JSON.stringify({access_token:oAuthResponse.data.access_token,expireTime:(new Date()).setSeconds(oAuthResponse.data.expires_in)}));
        return oAuthResponse.data.access_token;
      }else{
          return oAuthTokenObject.access_token;
      }
    } catch (err: any) {
      console.log(err.response?.data || err);
      return false;
    }
    return false;
  }
}

const oAuth2TokenForWebhookService = new OAuth2TokenForWebhookService();
export default oAuth2TokenForWebhookService;
