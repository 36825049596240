import { useState } from "react";
import { FeamEntities, IProps } from "../../../models/feam-entities";
import * as BootStrapIcon from 'react-bootstrap-icons';
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CwsMechanicsAssignedForm from "./CwsMechanicsAssignedForm";
import { mechanicsTypes, supervisorsTypes, avionicsType, leadsTypes, regularPay, overtimePay } from "../../../models/constants";
import { NoRecordFound } from "../common";
import { formatDateTime, sumOfTimes } from "../../../models/feam-utils";
import { useDirty, useSharedState } from "./DynamicWorkSheet";
import { useTokenState } from "../../../RequestInterceptors";

const initialMechanics: { mechanicsData: FeamEntities.IMechanics, action: 'add' | 'edit' | undefined } = { mechanicsData: { mechanics: [], isWorkShift: false, maintenanceTypeId: undefined }, action: undefined };

export default function CwsDynamicMechanicsContainer(props: IProps.ICwsDynamicMechanicsContainerProps<FeamEntities.ID407Data>) {
    const [mechanics, setMechanics] = useState({ ...initialMechanics });
    const [, setDeleteToggle] = useState(false);
    const { isDirty, setIsDirty } = useDirty();
    const { reload, setReload } = useSharedState();
    const { formProps, users, print, maintenanceTypes } = props;


    return (
        <>
            <div className="d-flex d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase">
                <div>MECHANICS ASSIGNED</div>
                <div className="d-inline-flex gap-5">
                    <div className="fw-bold justify-content-center">TOTAL HOURS:</div>
                    <div className="d-flex justify-content-center  gap-2">
                        <span className="fw-bold">Regular Time: {sumOfTimes(
                            //@ts-ignore 
                            formProps.values.mechanicsAssigneds?.filter(x => regularPay.includes(x.payCode)).flatMap(x => x.shiftHours)
                        )}</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-start gap-2">
                        <span className="fw-bold">Overtime: {sumOfTimes(
                            //@ts-ignore 
                            formProps.values.mechanicsAssigneds?.filter(x => overtimePay.includes(x.payCode)).flatMap(x => x.shiftHours)
                        )}</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-start gap-2">
                        <span className="fw-bold">OTHER: {sumOfTimes(
                            //@ts-ignore 
                            formProps.values.mechanicsAssigneds?.filter(x => !(regularPay.includes(x.payCode) || overtimePay.includes(x.payCode))).flatMap(x => x.shiftHours)
                        )}</span>
                    </div>
                    {!print && formProps.values.mechanicsAssigneds && formProps.values.mechanicsAssigneds.length > 0 &&
                        <div className="d-flex align-items-center">
                            <BootStrapIcon.PencilFill role="button" className="text-body" size={16} onClick={() => { setMechanics({ mechanicsData: { mechanics: [...formProps.values.mechanicsAssigneds ?? []], isWorkShift: formProps.values.isWorkShift ?? false, maintenanceTypeId: formProps.values.maintenanceTypeId }, action: 'edit' }) }} title="Edit Mechanics" />
                        </div>}

                </div>
            </div>
            {(!formProps.values.mechanicsAssigneds || formProps.values.mechanicsAssigneds.length === 0) &&
                <NoRecordFound message="No mechanics Assigned found" className="mb-3 mt-3" />
            }
            {formProps.values.mechanicsAssigneds && formProps.values.mechanicsAssigneds.length > 0 &&
                <>
                    {!formProps.values.isWorkShift && <table className="table dynamic-tail-table m-0 p-0 mt-2">

                        <thead className="">
                            <tr className="text-uppercase">
                                <th scope="col">NAME</th>
                                <th scope="col">IN</th>
                                <th scope="col">OUT</th>
                                <th scope="col">Hours</th>
                                <th scope="col">Pay</th>
                                <th scope="col">Position</th>
                                {!print && <th scope="col"></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {formProps.values.mechanicsAssigneds?.map((item, index) => {
                                return (
                                    <tr key={`${item.mechanicName}_${index}`}>
                                        <td>{item.mechanicName}</td>
                                        <td>{item.shiftStartTime}</td>
                                        <td>{item.shiftEndTime}</td>
                                        <td>{item.shiftHours}</td>
                                        <td>{item.payCode}</td>
                                        <td>{item.workTitleCode}</td>
                                        {!print && <td className="text-end">
                                            <div className="d-flex d-inline-flex gap-1">
                                                {/*//@ts-ignore */}
                                                <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteHandler(formProps, index)} title="Delete Mechanics" />
                                            </div>
                                        </td>}
                                    </tr>)
                            })
                            }
                        </tbody>
                    </table>}
                    {formProps.values.isWorkShift && <table className="table dynamic-tail-table m-0 p-0 mt-2">
                        <thead className="">
                            <tr className="text-uppercase">
                                <th scope="col">NAME</th>
                                <th scope="col" className="text-nowrap">Start Date</th>
                                <th scope="col" className="text-nowrap">End Date</th>
                                {formProps.values.isWorkShift && (!formProps.values.maintenanceTypeId || (formProps.values.maintenanceTypeId && !["AOG", "MRR", "FLT-Flight Mechanic"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId?.toString())?.name ?? ''))) && <th scope="col">Shift</th>}
                                <th scope="col">Hours</th>
                                <th scope="col">Pay</th>
                                <th scope="col">Position</th>
                                {!print && <th scope="col"></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {formProps.values.mechanicsAssigneds?.map((item, index) => {
                                return (
                                    <tr key={`${item.mechanicName}_${index}`}>
                                        <td>{item.mechanicName}</td>
                                        {formProps.values.isWorkShift && (!formProps.values.maintenanceTypeId || (formProps.values.maintenanceTypeId && !["AOG", "FLT-Flight Mechanic"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId?.toString())?.name ?? ""))) && <>
                                            <td>{!item.shiftStartDate ? "" : item.mechanicsAssignedId ? formatDateTime(new Date(item.shiftStartDate), true) : item.shiftStartDate}</td>
                                            <td>{!item.shiftEndDate ? "" : item.mechanicsAssignedId ? formatDateTime(new Date(item.shiftEndDate), true) : item.shiftEndDate}</td></>}
                                        {formProps.values.maintenanceTypeId && ["AOG", "MRR", "FLT-Flight Mechanic"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId?.toString())?.name ?? "") && <>
                                            <td>
                                                {!item.shiftStartDate ? "" : item.shiftStartDate ? formatDateTime(new Date(item.shiftStartDate)) : ''}
                                            </td>
                                            <td>
                                                {!item.shiftEndDate ? "" : item.shiftEndDate ? formatDateTime(new Date(item.shiftEndDate)) : ''}
                                            </td></>}
                                        {formProps.values.isWorkShift && (!formProps.values.maintenanceTypeId || (formProps.values.maintenanceTypeId && formProps.values.maintenanceTypeId && !["AOG", "MRR", "FLT-Flight Mechanic"].includes(maintenanceTypes.find(x => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId?.toString())?.name ?? ''))) && <td>{item.workShiftTime}</td>}
                                        <td>{item.shiftHours}</td>
                                        <td>{item.payCode}</td>
                                        <td>{item.workTitleCode}</td>
                                        {!print && <td className="text-end">
                                            <div className="d-flex d-inline-flex gap-1">
                                                {/*//@ts-ignore */}
                                                <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteHandler(formProps, index)} title="Delete Mechanics" />
                                            </div>
                                        </td>}
                                    </tr>)
                            })
                            }
                        </tbody>
                    </table>}
                </>

            }
            {!print && formProps.values.mechanicsAssigneds?.length === 0 && <button type="button" className="btn btn-outline-secondary btn-block w-100 text-uppercase m-0 p-0" onClick={() => setMechanics({ mechanicsData: { mechanics: [], isWorkShift: formProps.values.isWorkShift ?? false, maintenanceTypeId: formProps.values.maintenanceTypeId }, action: 'add' })} title="Add Mechanics"><BootStrapIcon.PlusCircleFill size={16} /> Add Mechanics</button>}
            <div className="mt-1 mb-1 d-flex d-inline-flex justify-content-between align-items-center header-col w-100 p-1 fw-bold rounded text-uppercase">
                <div className="d-flex justify-content-center align-items-start mr-3">
                    <span className="fw-bold">Total:</span>
                </div>
                <div className="d-flex justify-content-center align-items-start gap-2">
                    <span className="fw-bold">Mechanics: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => mechanicsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
                <div className="d-flex justify-content-end align-items-start gap-2">
                    <span className="fw-bold">Avionics: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => avionicsType.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
                <div className="d-flex justify-content-center align-items-start gap-2">
                    <span className="fw-bold ">Supervisors: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => supervisorsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
                <div className="d-flex justify-content-end align-items-start gap-2">
                    <span className="fw-bold">LEADS: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => leadsTypes.includes(x.workTitleCode)).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
                <div className="d-flex justify-content-end align-items-start gap-2">
                    <span className="fw-bold">Other: {sumOfTimes(
                        //@ts-ignore 
                        formProps.values.mechanicsAssigneds?.filter(x => ![
                            ...mechanicsTypes,
                            ...avionicsType,
                            ...supervisorsTypes,
                            ...leadsTypes
                        ].includes(x.workTitleCode ? x.workTitleCode.trim() : "")).flatMap(x => x.shiftHours)
                    )}</span>
                </div>
            </div>
            {mechanics.action !== undefined && <Formik
                initialValues={{ ...mechanics.mechanicsData }}
                enableReinitialize={true}
                onSubmit={async (
                    values: FeamEntities.IMechanics,
                    formikHelpers: FormikHelpers<FeamEntities.IMechanics>
                ) => {
                    await addAndUpdateMechanicsHandler(values, formikHelpers);
                }}
            >
                {(fprops: FormikProps<FeamEntities.IMechanics>) => {

                    return (
                        <Modal isOpen={mechanics.action !== undefined} scrollable size="lg">
                            <ModalHeader className="text-uppercase">
                                {mechanics.action && (mechanics.action === 'edit' ? "Edit Mechanics Assigned" : "Add Mechanics Assigned")}
                            </ModalHeader>
                            <ModalBody className="p-3">
                                <div className="mb-3">
                                    <CwsMechanicsAssignedForm formProps={fprops} users={users} maintenanceTypes={maintenanceTypes} />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-secondary" onClick={() => { fprops.submitForm(); }} title={mechanics.action === "add" ? "Add" : "Update"} disabled={fprops.isSubmitting}><BootStrapIcon.SaveFill size={16} />&nbsp;{mechanics.action === 'add' ? "Add" : "Update"}</button>
                                <button type="button" className="btn btn-outline-secondary" onClick={() => mechanicsAssignedCancelHandler(fprops)} title="Cancel"><BootStrapIcon.DashCircleFill size={16} />&nbsp;Cancel</button>
                            </ModalFooter>
                        </Modal>)
                }}
            </Formik >}
        </>

    )

    function resetMechanicsAssigned(formikHelpers: FormikHelpers<FeamEntities.IMechanics>) {
        formikHelpers.resetForm({ values: { mechanics: [...formProps.values.mechanicsAssigneds ?? []], isWorkShift: formProps.values.isWorkShift ?? false, maintenanceTypeId: formProps.values.maintenanceTypeId } });
    }

    function mechanicsAssignedCancelHandler(fprops: FormikProps<FeamEntities.IMechanics>) {
        setMechanics({ mechanicsData: { mechanics: [], isWorkShift: false, maintenanceTypeId: undefined }, action: undefined });
        fprops.resetForm();
    }
    function addAndUpdateMechanicsHandler(values: FeamEntities.IMechanics, formikHelpers: FormikHelpers<FeamEntities.IMechanics>): void | Promise<any> {
        if (isValid(values)) {
            formProps.setFieldValue("mechanicsAssigneds", [...values.mechanics]);
            formProps.setFieldValue("isWorkShift", values.isWorkShift);
            //@ts-ignore 
            const totalSum = values.mechanics.length > 0 ?
                sumOfTimes(
                    //@ts-ignore 
                    values.mechanics.flatMap(x => x.shiftHours)) : "00:00";
            formProps.setFieldValue("totalSum", totalSum);
            setMechanics({ mechanicsData: { mechanics: [], isWorkShift: false, maintenanceTypeId: undefined }, action: undefined });
            setReload(!reload);
            resetMechanicsAssigned(formikHelpers);
            setIsDirty(true);

        }
    }
    function deleteHandler(formProps: FormikProps<FeamEntities.ID407Data>, index: number) {
        //@ts-ignore 
        const name = formProps.values.mechanicsAssigneds[index].mechanicName;
        if (window.confirm(`Are you sure! You want to delete the "${name}" from Mechanics Assigned list.`)) {
            formProps.values.mechanicsAssigneds?.splice(index, 1);
            const totalSum = sumOfTimes(
                //@ts-ignore 
                formProps.values.mechanicsAssigneds.flatMap(x => x.shiftHours));
            formProps.setFieldValue("totalSum", totalSum);
            setDeleteToggle(prev => !prev);
            setReload(!reload);
            setIsDirty(true);
        }
    }

    function isValid(values: FeamEntities.IMechanics): boolean {
        if (values.mechanics && values.mechanics.filter((x) =>
            (x.mechanicName || x.workTitleCode || x.payCode || x.shiftHours)
            &&
            (!x.mechanicName || x.mechanicName.trim().length===0  || !x.workTitleCode || !x.payCode || !x.shiftHours)).length > 0) {
            const hoursMessage = !values.isWorkShift
                ? "In, Out"
                : values.isWorkShift &&
                    (!formProps.values.maintenanceTypeId ||
                        (formProps.values.maintenanceTypeId &&
                            !["AOG", "MRR"].includes(
                                //@ts-ignore
                                maintenanceTypes.find((x) => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.name
                            )))
                    ? "Start Date, End Date, Shift"
                    : ["AOG", "MRR"].includes(
                        //@ts-ignore
                        maintenanceTypes.find((x) => x.maintenanceTypeId?.toString() === formProps.values.maintenanceTypeId.toString())?.name
                    )
                        ? "Start Date, End Date"
                        : "Hours";
            alert("The form requires " + hoursMessage + " , Pay and Position along with Mechanic name.");
            return false;
        }
        return true;
    }

}