import { FeamEntities, IProps } from "../../../models/feam-entities";
import { FeamFormArrayAutoCompleteInputForEmployee, FeamFormArrayFieldInput, FeamFormArrayFieldSelect, FeamFormArrayTimeFieldInput } from "../../common";
import * as BootStrapIcon from 'react-bootstrap-icons';

export default function CwsMechanicsAssignedWSForm(props: IProps.ICwsMechanicsAssignedWSFormProps<FeamEntities.IMechanics>) {
    const { formProps, deleteHandler, users, workTitles, payTypes } = props;
    return (
        <table className="table table-ws">
            <thead>
                <tr className="text-uppercase">
                    <th scope="col" style={{ width: "150px" }}>NAME</th>
                    <th scope="col" style={{ width: "90px" }} className="text-nowrap">Start Date</th>
                    <th scope="col" style={{ width: "90px" }} className="text-nowrap">End Date</th>
                    <th scope="col" style={{ width: "50px" }}>Shift</th>
                    <th scope="col" style={{ width: "50px" }}>Hours</th>
                    <th scope="col" style={{ width: "100px" }}>Pay</th>
                    <th scope="col" style={{ width: "100px" }}>Position</th>
                    <th scope="col" style={{ width: "20px" }}></th>
                </tr>
            </thead>
            <tbody>
                {formProps.values.mechanics?.map((_: FeamEntities.IMechanicsAssigned, index: number) => {
                    return (
                        <tr key={`mechanicsAssigned_${index}`}>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayAutoCompleteInputForEmployee formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="mechanicName" className="customer-worksheet-form-control" data={[...users]} onchangeHandler={props.onAutoCompleteChangeHandler} />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayFieldInput type="date" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftStartDate" onBlur={(e) => { setTimeDiff('mechanics', formProps.values.mechanics[index].workShiftTime ?? "hh:mm", e.target.value, formProps.values.mechanics[index].shiftEndDate, index); formProps.handleBlur(e) }} className="customer-worksheet-form-tcontrol" max={formProps.values.mechanics[index].shiftEndDate} />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayFieldInput type="date" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftEndDate" onBlur={(e) => { setTimeDiff('mechanics', formProps.values.mechanics[index].workShiftTime ?? "hh:mm", formProps.values.mechanics[index].shiftStartDate, e.target.value, index); formProps.handleBlur(e) }} className="customer-worksheet-form-tcontrol" min={formProps.values.mechanics[index]?.shiftStartDate} />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="workShiftTime" onBlur={(e) => { setTimeDiff('mechanics', e.target.value ?? "hh:mm", formProps.values.mechanics[index].shiftStartDate, formProps.values.mechanics[index].shiftEndDate, index); formProps.handleBlur(e) }} className="customer-worksheet-form-tcontrol" step={1} min={0} max={24} pattern="[0-9]" />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="shiftHours" className="customer-worksheet-form-tcontrol" readOnly={true} />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayFieldSelect defaultText="Pay" data={[...payTypes]} formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="payCode" className="customer-worksheet-form-control" />
                            </td>
                            <td>
                                {/**@ts-ignore */}
                                <FeamFormArrayFieldSelect defaultText="Role" data={[...workTitles]} formProps={formProps} feamArraySelector="mechanics" feamItemIndex={index} feamSelector="workTitleCode" className="customer-worksheet-form-control" />
                            </td>
                            <td>
                                <td className="text-end">
                                    <div className="d-flex d-inline-flex gap-1 align-items-center">
                                        <BootStrapIcon.TrashFill role="button" className="text-danger" size={16} onClick={() => deleteHandler(index)} title="Delete Mechanics" />
                                    </div>
                                </td>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )


    function setTimeDiff(arraySelector: string, workshift: string, start: string, end: string, index: number) {
        if (!start || !end || start == '' || end == '' || workshift == '' || (workshift === "00:00" || (workshift.includes("hh") || workshift.includes("mm")))) {
            if (start && !end) {
                formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftEndDate"}`, start);
            }
            formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, "");
            return;
        }
        const workshiftCollection = workshift?.split(":");

        let startDate = new Date(new Date(start.split('T')[0]).setHours(0, 0, 0, 0));
        let endDate = new Date(new Date(end.split('T')[0]).setHours(0, 0, 0, 0));
        endDate.setDate(endDate.getDate() + 1);
        let diff = endDate.getTime() - startDate.getTime();
        const days =  Math.floor(diff / (1000 * 60 * 60 * 24));
        let hours = +workshiftCollection[0] * days;
        let totalMinutes = +workshiftCollection[1] * days;
        hours += Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        const hm = (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
        formProps.setFieldValue(`${arraySelector}.${[index]}.${"shiftHours"}`, hm);
    }
}